import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<>
			<div className="footer-partners">
				<div className="body">
				<div className="partners partners-1"><span>Supermicro Arista Corero</span></div>
				<div className="partners partners-2"><span>Juniper Veeam Equinix</span></div>
				</div>
			</div>
			<div id="footer">
				<div className="bar" />
				<div className="body">
				<div className="footer-links" id="nav">
					<ul>
					<li><Link to="/gameservers">Game Servers</Link></li>
					<li><Link to="/voiceservers/teamspeak-3">Voice</Link></li>
					<li><Link to="/webhosting/australia">Australian Web Hosting</Link></li>
					<li><Link to="/dedicated/australia">Australian Dedicated Servers</Link></li>
					<li><Link to="/dedicated/asia">Asian Dedicated Servers</Link></li>
					<li><Link to="/vps/australia">Australian VPS Hosting</Link></li>
					</ul>
					<ul>
					<li><Link to="/">Home</Link></li>
					<li><Link to="/about">About Us</Link></li>
					<li />
					<li><a href="https://billing.gogaming.com/contact.php">Contact Us</a></li>
					</ul>
					<ul>
					<li><a href="https://gogaming.com/legal/tos.pdf">Terms of Service</a></li>
					<li><a href="https://gogaming.com/legal/privacy-policy.pdf">Privacy Policy</a></li>
					</ul>
				</div>
				<div className="footer-social">
					<a href="https://www.facebook.com/GoGameServers/" className="fb" target="_blank">Like us on <span>Facebook</span></a>
					<a href="https://twitter.com/gogameservers" className="tw" target="_blank">Follow us on <span>Twitter</span></a>
				</div>
				<div className="footer-tagline">
					<strong><em>GO</em>GAMING</strong>
					GAME SERVER HOSTING
				</div>
				</div>
				<div className="bottom">
				<div className="copyright">
					Copyright &copy; {(new Date().getFullYear())} GoGaming - All Rights Reserved.
				</div>
				</div>
			</div>
		</>
	);
}

export default Footer;
