import React from 'react';
import { Link } from 'react-router-dom';

function City({data, type}) {
	let localisedType = type === "dedicated" ? "Dedicated Servers" : "VPS Hosting";
	return (
		<div key={data.url}>
			<div className="server-type">
				{data.localised} {localisedType}
			</div>
			
			<div className="server-table">
				<div className="th">
					<div>{type === "dedicated" ? "Processor" : "CPU Cores"}</div>
					{type === "dedicated" && (
					<div>Cores</div>)}
					<div>RAM</div>
					<div style={{width: "15%"}}>Storage</div>
					<div>Transfer</div>
					<div>Price</div>
					<div style={{width: "15%"}}>&nbsp;</div>
				</div>

				{data.servers.map((server) => (
					<div key={server.url} className="server">
						<div className="proc">
							{server.processor || server.cores}
						</div>
						{type === "dedicated" && (
						<div>{server.cores}</div>)}
						<div>{server.ram}</div>
						<div style={{width: "15%"}}>{server.storage}</div>
						<div>{server.transfer}</div>
						<div className="price"><span>{server.price}</span>/mo</div>
						<div style={{width: "15%"}} className="order"><a href={server.url}>CONFIGURE</a></div>
					</div>
				))}
			</div>
		</div>
	);
}

function Servers(props) {
	return (
		<>
		<div className="option-bar">
			<div className="body">
				<Link className={props.isCountryView ? "selected" : undefined} to={`/${props.type}/${props.data.url}`}>All</Link>
				{props.data.cities.map((city) => (
					<Link className={props.city === city.url ? "selected" : undefined} to={`/${props.type}/${props.data.url}/${city.url}`}>{city.localised}</Link>
				))}
			</div>
		</div>

		<div className="body">
			{props.isCountryView ? props.data.cities.map((city) => (
				<City key={city.url} data={city} type={props.type}></City>
			)) : <City data={props.cityData} type={props.type}></City>}
		</div>
		</>
	);
}

export default Servers;
