import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import GSHeader from "images/assets/codww2.jpg";

import { PopularGames, AllGames, AllAllGames } from 'resources/games.js';

function PopularGame(props) {
	const [image, setImage] = useState(false);
	useEffect(() => {
		import(`../../../${props.largeimage}`)
		.then((image) => {
			setImage(image);
		})
	}, [props.largeimage]);

	return (
		<div className="popular-game">
			{props.hasflair ? (
				<div className="flair" style={{color: props.flaircolor}}>
					<span>{props.flairtext}</span>
				</div>
			) : null}

			<div className="image" style={{backgroundImage: `url(${image.default})`}}></div>
			<div className="title">
				<strong>{props.bigtitle}</strong>
				<span>{props.subtitle}</span>
			</div>
			<div className="price">
				FROM
				<strong>${props.price}</strong>
				PER MONTH
			</div>
			<Link to={`/gameservers/${props.url}`} className="info orderbutton"><i className="fa fa-info-circle"></i> DETAILS</Link>
			<a href={props.cart} className="cart orderbutton"><i className="fa fa-shopping-cart"></i> ORDER NOW</a>
		</div>
	);
}

function Game(props) {
	const [image, setImage] = useState(false);
	useEffect(() => {
		import(`../../../${props.img}`)
		.then((image) => {
			setImage(image);
		})
	}, [props.img]);

	return (
		<div className={`game ${props.hide ? "hidden" : ""}`}>
			<div className="image" style={{backgroundImage: `url(${image.default})`}}><div></div></div>
			<div className="title">
				<Link to={`/gameservers/${props.url}`}><strong>{props.title}</strong></Link>
			</div>
			
			<div className="price">
				from <strong>${props.price}</strong> per month
			</div>
			
			<div className="buttonset">
				<Link to={`/gameservers/${props.url}`} className="infodark orderbutton"><i className="fa fa-info-circle"></i> DETAILS</Link>
				<a href={props.cart} className="cartdark orderbutton"><i className="fa fa-shopping-cart"></i> ORDER NOW</a>
			</div>
			
			<Link className="more-button" to={`/gameservers/${props.url}`}></Link>
		</div>
	)
}

function GameServers() {
	const [noGamesFound, setNoGamesFound] = useState(true);
	const [text, setText] = useState(null);

	function searchChange(e) {
		console.log(e);
		let text = e.target.value;

		if (text != "") {
			setText(text);
			let found = false;
			AllAllGames.some((game) => {
				if (game.title.toLowerCase().includes(text.toLowerCase())) {
					found = true;
					return true;
				}
			});

			setNoGamesFound(found);
		} else {
			setText(null);
			setNoGamesFound(true);
		}
	}

	return (
		<div className="GameServers">
			{/*<meta name="Description" content="Whether it is for a competitive match, or a casual game with friends, GoGaming has the perfect game server package for you! We offer old school games, all the way up to the latest titles!" />
			?php
			$title = "Rent Game Servers";
			include("header.php");
			?*/}
			<div className="gameservers-header" style={{backgroundImage: `url(${GSHeader})`}}>
				<div className="body">
				<div className="header-box">
					<div className="first-part">
					<div className="title">
						GAME SERVERS
					</div>
					<div className="subtitle">
						Whether it is for a competitive match, or a casual game with friends,
						GoGaming has the perfect game server package for you!
					</div>
					</div>
					<div className="second-part">
					<div className="search-title">Search Our Games:</div>
					<input onChange={searchChange} type="search" id="gamesearch" placeholder="game name to search ..." />
					</div>
				</div>
				</div>
			</div>

			<div className={`popular-games-back ${text ? "hidden" : ""}`}>
				<div className="body">
					<div className="popular-games-list">
						{PopularGames.map((game) => <PopularGame key={game.url} {...game}/>)}
					</div>
				</div>
			</div>

			<div className="game-list-back">
				<div className="body">
				<div className="game-list">
					{AllAllGames.map((game) => <Game hide={text && !game.title.toLowerCase().includes(text.toLowerCase())} key={game.url} {...game}/>)}
				</div>
				<div className={`nogames ${noGamesFound ? "hidden" : ""}`}>
				No games match your search.
				</div>
				</div>
			</div>
		</div>
	);
}

export default GameServers;
