import React from 'react';
import { Helmet } from 'react-helmet';

function Affiliate() {
	return (
		<div className="Affiliate">
			<Helmet>
				<title>Affiliate System - GoGaming</title>
				<meta name="description" content="GoGaming Affiliate program. Come and earn some quick and easy pocket money! You will earn 10% recurring per sale you refer to us!" />
			</Helmet>
			<div className="aff-header">
				<div className="body">
				<div className="big-heading">
					<div className="container">
					<div className="title">
						Affiliate System
					</div>
					<div className="subtitle">
						Join GoGaming’s affiliate program and earn quick easy money for every sale that you make, you can even track the sales in real-time!
					</div>
					</div>
				</div>
				</div>
				<div className="body">
				<ul className="aff-blocks">
					<li><i className="fa fa-clock-o" /> <div>Join and start selling with the click of a button</div></li>
					<li><i className="fa fa-paypal" /> <div>Get paid via account credit or to a PayPal account</div></li>
					<li><i className="fa fa-picture-o" /> <div>You can use our premade banners, or make your own</div></li>
					<li><i className="fa fa-percent" /> <div>Earn a massive 10% recurring per referral!</div></li>
					<li><i className="fa fa-tachometer" /> <div>Keep an eye on your sales via our billing system</div></li>
				</ul>
				</div>
			</div>
			<div className="body">
				<div className="big-heading ">
				<div className="supertitle">
					AFFILIATE FEATURES &amp; FAQ
				</div>
				<div className="subtitle">
					INCLUDED WITH EVERY ACCOUNT
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					<div className="fa fa-area-chart" />
					Easy to track your sales
					</div>
					<div className="text">
					We record and display every affiliate sale made in an easy to read fashion. You can easily keep track of your sales and profits via our billing system.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-feed" />
					Global Brand
					</div>
					<div className="text">
					Sell our services with confidence, GoGaming is a globally recognisable gaming brand. The client’s you refer will be well looked after by our sales, support and billing teams.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-dashboard" />
					Monthly Account Balance
					</div>
					<div className="text">
					Keep on top of your sales with our monthly affiliate emails! We will let you know how much money you’ve made each month, so you don’t need to manually login and check!
					</div>
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					<div className="fa fa-eject" />
					I have a specific request?
					</div>
					<div className="text">
					We are always happy and willing to work with our affiliates, if you have any custom requests don’t hesitate to get in contact with our dedicated support team!
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-balance-scale" />
					What are you affiliate rates?
					</div>
					<div className="text">
					You will earn a massive 10% recurring per sale you make through our affiliate system. This is for the life of the service, as long as the client is renting the server through GoGaming you will be paid.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-calendar" />
					How often will I be paid out?
					</div>
					<div className="text">
					GoGaming offers a very low minimum payout amount of only $10.00, we also don’t limit the number of payouts you can do each month. Simply lodge a ticket with our staff and we will transfer your affiliate payments within 24 hours.
					</div>
				</div>
				</div>
			</div>
			<div className="center padded">
				<a href="https://billing.gogaming.com/affiliates.php" className="bigbutton">
				JOIN OUR AFFILIATE PROGRAM
				<i className="fa fa-share" />
				</a>
			</div>
			{/*?php include("footer.php"); ?*/}
		</div>
	);
}

export default Affiliate;
