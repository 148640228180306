const VPS = [
	{
		"url": "australia",
		"localised": "Australian",
		"description": "Premium, Reliable, Secure and Fast Australian VPS Servers. Hosting from only $10",
		"cities": [
			{
				"url": "sydney",
				"localised": "Sydney",
				"description": "Fast VPS in the Sydney, Australia. Hosting from $10",
				"default": true,
				"servers": [
					{
						"type": "value",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "1",
							"Ghz": "3",
						},
						"ram": "1 GB",
						"storage": "20 GB",
						"transfer": "500 GB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",

						"price": {
							"dollars": "10",
							"cents": "00",
						},

						"url": "https://billing.godedicated.com/cart.php?a=add&pid=86",
					},
					{
						"type": "professional",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "2",
							"Ghz": "3",
						},
						"ram": "2 GB",
						"storage": "40 GB",
						"transfer": "1 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "20",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=88",
					},
					{
						"type": "ultimate",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "2",
							"Ghz": "3",
						},
						"ram": "4 GB",
						"storage": "60 GB",
						"transfer": "1 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "30",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=89",
					},
					{
						"type": "business",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "4",
							"Ghz": "3",
						},
						"ram": "8 GB",
						"storage": "80 GB",
						"transfer": "2 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "60",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=90",
					}
				],
			},
			{
				"url": "perth",
				"localised": "Perth",
				"description": "Fast VPS in the Perth, Australia. Hosting from $10",
				"default": true,
				"servers": [
					{
						"type": "value",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "1",
							"Ghz": "3",
						},
						"ram": "1 GB",
						"storage": "20 GB",
						"transfer": "750 GB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",

						"price": {
							"dollars": "10",
							"cents": "00",
						},

						"url": "https://billing.godedicated.com/cart.php?a=add&pid=86",
					},
					{
						"type": "professional",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "2",
							"Ghz": "3",
						},
						"ram": "2 GB",
						"storage": "40 GB",
						"transfer": "1.5 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "20",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=88",
					},
					{
						"type": "ultimate",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "2",
							"Ghz": "3",
						},
						"ram": "4 GB",
						"storage": "60 GB",
						"transfer": "2 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "30",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=89",
					},
					{
						"type": "business",
						"processorVendor": "Intel",
						"cpu": {
							"numCores": "4",
							"Ghz": "3",
						},
						"ram": "8 GB",
						"storage": "80 GB",
						"transfer": "4 TB",
						"connectionSpeed": "100 Mbit",
						"ips": "1",
						"price": {
							"dollars": "60",
							"cents": "00",
						},
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=90",
					}
				],
			},
		]
	}
];

export default VPS;
