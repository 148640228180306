const WebHosting = [
	{
		"url": "australia",
		"localised": "Australian",
		"abbrevation": "AUS",
		"description": "Enterprise, Performance and Secure Australian Web Hosting. Expert solutions from only $7.99/mo",
		"plans": [
			{
				"type": "Basic",
				"dollar": "7",
				"cents": "99",
				"storage": "15 GB",
				"transfer": "Unlimited",
				"cpu": "100",
				"domains": "1",
				"subdomains": "Unlimited",
				"databases": "5",
				"emails": "10",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=99",
			},
			{
				"type": "Intermediate",
				"dollar": "15",
				"cents": "99",
				"storage": "25 GB",
				"transfer": "Unlimited",
				"cpu": "150",
				"domains": "5",
				"subdomains": "Unlimited",
				"databases": "Unlimited",
				"emails": "Unlimited",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=100",
			},
			{
				"type": "Advanced",
				"dollar": "29",
				"cents": "99",
				"storage": "50 GB",
				"transfer": "Unlimited",
				"cpu": "250",
				"domains": "Unlimited",
				"subdomains": "Unlimited",
				"databases": "Unlimited",
				"emails": "Unlimited",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=101",
			},
		],
	},
	{
		"url": "america",
		"localised": "American",
		"abbrevation": "USA",
		"description": "Enterprise, Performance and Secure American Web Hosting. Expert solutions from only $7.99/mo",
		"plans": [
			{
				"type": "Basic",
				"dollar": "7",
				"cents": "99",
				"storage": "15 GB",
				"transfer": "Unlimited",
				"cpu": "100",
				"domains": "1",
				"subdomains": "Unlimited",
				"databases": "5",
				"emails": "10",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=108",
			},
			{
				"type": "Intermediate",
				"dollar": "15",
				"cents": "99",
				"storage": "25 GB",
				"transfer": "Unlimited",
				"cpu": "150",
				"domains": "5",
				"subdomains": "Unlimited",
				"databases": "Unlimited",
				"emails": "Unlimited",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=109",
			},
			{
				"type": "Advanced",
				"dollar": "29",
				"cents": "99",
				"storage": "50 GB",
				"transfer": "Unlimited",
				"cpu": "250",
				"domains": "Unlimited",
				"subdomains": "Unlimited",
				"databases": "Unlimited",
				"emails": "Unlimited",
				"url": "https://billing.godedicated.com/cart.php?a=add&pid=110",
			},
		],
	},
];

export default WebHosting;
