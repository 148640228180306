import React from 'react';

function WebPackage(props) {
	return (
		<div className="box">
			<div className="title">
				{props.type}
			</div>
			<div className="price">
				<div>
					<em>${props.dollar}<sup>.{props.cents}</sup></em>
					/mo
				</div>
			</div>
			<ul>
				<li className="cpanel">
					Powered by <b style={{"color": "#ff6c2c"}}>cPanel</b>
				</li>
				<li>
					<em>{props.storage} </em>
					SSD Disk Space
				</li>
				{props.cPanelAccounts && (
				<li>
					<em>{props.cPanelAccounts} </em>
					cPanel Accounts
				</li>)}
				<li>
					<em>{props.transfer} </em>
					Monthly Transfer
				</li>
				{props.cpu && (
				<li>
					<em>{props.cpu}% </em>
					CPU Allowance
				</li>)}
				<li className="small">
					<em>{props.domains} </em>
					Domain Names Hosted
				</li>
				{props.parkedDomains && (
				<li className="small">
					<em>{props.parkedDomains} </em>
					Parked Domains
				</li>)}
				{props.subdomains && (
				<li className="small">
					<em>{props.subdomains} </em>
					Subdomains
				</li>)}
				{props.databases && (
				<li className="small">
					<em>{props.databases} </em>
					MySQL Databases
				</li>)}
				<li className="small">
					<em>{props.emails} </em>
					Email Accounts
				</li>
			</ul>
			
			<a href={props.url} className="button">ORDER NOW</a>
		</div>
	);
}

function WebPackages(props) {
	return (
		<div className="web-hosting-boxes">
			{props.packages.map((pkg) => (
				<WebPackage key={pkg.type} {...pkg}></WebPackage>
			))}
		</div>
	);
}

export default WebPackages;
