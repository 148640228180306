import React from 'react';
import { Helmet } from 'react-helmet';

import BigMap from 'components/layouts/BigMap/BigMap.jsx';

function About() {
	return (
		<div className="About">
			<Helmet>
				<title>About Us - GoGaming</title>
				<meta name="description" content="Learn More about GoGaming, we are a market innovator in multiplayer online game server hosting, we are constantly looking to push the boundaries of what’s possible." />
			</Helmet>
			<div className="about-header">
				<div className="body">
				<div className="big-heading">
					<div className="container">
					<div className="title">
						GOGAMING CORE VALUES
					</div>
					<div className="subtitle">
						GoGaming is a market innovator in multiplayer online game server hosting, we are constantly looking to push the boundaries of what’s possible. Our game platform has been designed from the ground up to provide you the gamer with unmatched performance at an affordable price.
					</div>
					</div>
				</div>
				</div>
				<div className="about-blocks-back">
				<div className="body">
					<div className="about-blocks">
					<div className="about-block">
						<div className="icon-draw icd-1" />
						<div className="title">
						Dedicated Support Team
						</div>
						<div className="text">
						Our support staff are all in-house trained by our management team, to ensure high quality responses. They strive to response quickly and with a sophisticated level of detail to any question you may have. Don’t hesitate to contact the GoGaming support team today.
						</div>
					</div>
					<div className="about-block">
						<div className="icon-draw icd-2" />
						<div className="title">
						Gaming Hardware
						</div>
						<div className="text">
						GoGaming pushes the boundaries when it comes to hardware, we offer the latest and greatest equipment for our gamers. Don’t forget, we are gamers just like you! We know the importance of top of the line equipment to run your server at its peak performance.
						</div>
					</div>
					<div className="about-block">
						<div className="icon-draw icd-3" />
						<div className="title">
						Have Feedback?
						</div>
						<div className="text">
						We strive to do our best when it comes to gaming, but do you have a suggestion for us? Don’t worry it won’t fall on deaf ears, we love our clients providing feedback. It’s what helps us mature and grow as a gaming company. Don’t hesitate to contact us with your suggestions!
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div className="home-about">
				<div className="body">
				<h1>
					SO WHY <span className="gogaming-colors"><em>GO</em>GAMING</span> FOR SERVER HOSTING?
				</h1>
				<div className="desc">
					Our ambition and absolute passion is gaming, we strive to give you the gamer complete control over your server. Our job is simple, provide a high-quality network and top of the line hardware to keep your server running! The rest is entirely up to you! What are you waiting for? Jump on board with the GoGaming family today!
				</div>
				</div>
				<div className="bar">
				<div className="body">
					<div className="home-about-block">
					<h2><i className="fa fa-asl-interpreting" /> Customer Service</h2>
					<p>
						Whether you have a quick question about your server, or need an in-depth assistance on a managed dedicated server GoGaming has you covered. We pride ourselves on interactions with our customers, we are always here to help when you need it. Don’t hesitate to contact us today.
					</p>
					</div>
					<div className="home-about-block">
					<h2><i className="fa fa-cubes" /> Instant Activation and Setup</h2>
					<p>
						GoGaming knows time is of the essence, with us there is no more waiting for your hosting to be setup! We offer instant provisioning on our game, voice and web servers. You’ll be online within minutes of ordering your server.
					</p>
					</div>
				</div>
				</div>
				<a href="https://billing.gogaming.com/contact.php" className="bigbutton">CONTACT US <i className="fa fa-angle-double-right" /></a>
			</div>
			<BigMap />
		</div>
	);
}

export default About;
