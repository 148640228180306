import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import 'css/style.css';

function Error404() {
	const [maxHeight, setMaxHeight] = useState(0);

	useEffect(() => {
		let tempMaxHeight = maxHeight;
		for (let box of document.getElementsByClassName('box-text')) {
			if (box.clientHeight > tempMaxHeight) tempMaxHeight = box.clientHeight;
		}
		setMaxHeight(tempMaxHeight - 39); // minus padding size
	}, []);

	return (
		<>
		<Helmet>
			<title>Oops 404 - GoGaming</title>
			<meta name="description" content="" />
		</Helmet>
		<div className="home-about">
		<div className="body">
			<h1>
			OOPS... <span className="gogaming-colors"><em>404</em>Error</span>
			</h1>
			<div className="desc">
			Looks like the page you are looking for doesn’t exist or has moved to a new location.
			Use the following links to start your search again.
			</div>
		</div>
		<div className="body">
			<div className="home-services">
			<div className="service service-icon-game" style={{color: '#0291d6'}}>
				<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
				<div className="title">
				GAME SERVERS
				</div>
				<div className="price">
				<sup>From</sup>
				<div className="dollar"><em>2</em>.50</div>
				<small>PER MONTH</small>
				</div>
				<div className="content">
				<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
					No expense is spared when it comes to our game servers, boasting the latest titles on the fastest hardware.
				</div>
				<span className="button">
					<a href="gameservers.php">
					GO <i className="fa fa-angle-double-right" />
					</a>
				</span>
				</div>
			</div>
			<div className="service service-icon-voice" style={{color: '#009c4e'}}>
				<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
				<div className="title">
				TS3 VOICE SERVERS
				</div>
				<div className="price">
				<sup>From</sup>
				<div className="dollar"><em>2</em>.50</div>
				<small>PER MONTH</small>
				</div>
				<div className="content">
				<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
					Whether you need a Teamspeak 3 server for your team, community or business we’ve got you covered.
				</div>
				<span className="button">
					<a href="/voiceservers/teamspeak-3.php">
					GO <i className="fa fa-angle-double-right" />
					</a>
				</span>
				</div>
			</div>
			<div className="service service-icon-dedi" style={{color: '#677b00'}}>
				<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
				<div className="title">
				DEDICATED
				</div>
				<div className="price">
				<sup>From</sup>
				<div className="dollar"><em>150</em></div>
				<small>PER MONTH</small>
				</div>
				<div className="content">
				<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
					High quality, blazing fast dedicated servers for when your gaming community needs some serious power.
				</div>
				<span className="button">
					<a href="/australiadedicated.php">
					GO <i className="fa fa-angle-double-right" />
					</a>
				</span>
				</div>
			</div>
			<div className="service service-icon-hosting" style={{color: '#0291d6'}}>
				<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
				<div className="title">
				VPS HOSTING
				</div>
				<div className="price">
				<sup>From</sup>
				<div className="dollar"><em>10</em>.00</div>
				<small>PER MONTH</small>
				</div>
				<div className="content">
				<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
					GoGaming also offers business grade VPS servers, keeping you connected to the cloud.
				</div>
				<span className="button">
					<a href="/vps-australia.php">
					GO <i className="fa fa-angle-double-right" />
					</a>
				</span>
				</div>
			</div>
			</div>
		</div>
		<a href="https://billing.gogaming.com/contact.php" className="bigbutton">CONTACT US <i className="fa fa-angle-double-right" /></a>
		</div>
		{/*?php include("footer.php"); ?*/}
		</>
	);
}

export default Error404;
