import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './images/font-awesome-4.7.0/css/font-awesome.min.css';
import './fonts.css';

import './css/reset.css';
import './css/style.css';
import './css/style-custom.css';

import Nav from './components/layouts/Nav/Nav.jsx';
import Footer from './components/layouts/Footer/Footer.jsx';
import ScrollToTop from 'components/layouts/ScrollToTop/ScrollToTop.jsx';

import Home from './components/pages/Home/Home.jsx';
import About from './components/pages/About/About.jsx';
import Affiliate from './components/pages/Affiliate/Affiliate.jsx';
import DDoSProtection from './components/pages/DDoSProtection/DDoSProtection.jsx';
import GameServers from './components/pages/GameServers/GameServers.jsx';
import GameServer from './components/layouts/GameVoiceServer/GameVoiceServer.jsx';
import Error404 from './components/pages/Error404/Error404.jsx';
import Dedicated from './components/pages/Dedicated/Dedicated.jsx';
import VPS from './components/pages/VPS/VPS.jsx';
import WebHosting from './components/pages/WebHosting/WebHosting.jsx';

function App() {
	return (
		<div className="App">
			<Router>
				<Nav />
					<ScrollToTop />
					<div id="page">
						<Switch>
							<Route path="/" exact component={Home} />
							<Route path="/about" exact component={About} />
							<Route path="/affiliate-program" exact component={Affiliate} />
							<Route path="/ddos-protection" exact component={DDoSProtection} />
							<Route path="/gameservers" exact component={GameServers} />
							<Route path="/gameservers/:game" exact component={GameServer} />
							<Route path="/voiceservers/:voice" exact component={GameServer} />
							<Route exact path="/dedicated/:country" component={Dedicated} />
							<Route exact path="/dedicated/:country/:city" component={Dedicated} />
							<Route exact path="/vps/:country" component={VPS} />
							<Route exact path="/vps/:country/:city" component={VPS} />
							<Route exact path="/webhosting/:country" component={WebHosting} />
							<Route component={Error404} />
						</Switch>
						<Footer />
					</div>
			</Router>
		</div>
	);
}

export default App;
