import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import BigMap from 'components/layouts/BigMap/BigMap.jsx';

import arkMinPic from 'images/slider/ark-min.jpg';
import vpsMinPic from 'images/slider/vps-min.jpg';
import networkMinPic from 'images/slider/network-min.jpg';
import equinixMinPic from 'images/slider/equinix-min.jpg';

function Home() {
	const [selectedTab, setSelectedTab] = useState({index: 0, short: true});
	const [maxHeight, setMaxHeight] = useState(0);

	useEffect(() => {
		function showNextTab() {
			let nextTab = selectedTab.index < 3 ? selectedTab.index + 1 : 0;
			setSelectedTab({index: nextTab, short: true});
		}
		
		let timeout = setTimeout(showNextTab, selectedTab.short ? 6500 : 15000);

		return () => {
			clearTimeout(timeout);
		};
	}, [selectedTab.index]);

	useEffect(() => {
		let tempMaxHeight = maxHeight;
		for (let box of document.getElementsByClassName('box-text')) {
			if (box.clientHeight > tempMaxHeight) tempMaxHeight = box.clientHeight;
		}
		setMaxHeight(tempMaxHeight - 39); // minus padding size.
	}, []);

	return (
		<div className="Home">
			<Helmet>
				<title>Premium Game, Voice, Web & Dedicated Servers - GoGaming</title>
				<meta name="description" content="GoGaming offers premium quality services at affordable prices! Game & Voice Servers, Web & VPS Hosting, Dedicated Servers all with enterprise DDoS protection." />
			</Helmet>
			<div className="slider-area">
				<div className={`slider type-1 ${selectedTab.index != 0 ? "hidden" : ""}`}>
					<div className="bkg-image" style={{backgroundImage: `url(${arkMinPic})`}} />
					<div className="body">
						<div className="title" style={{color: '#fff'}}>
							ARK: SURVIVAL EVOLVED
						</div>
						<div className="subtitle" style={{color: '#fff'}}>
							Prepare to survive in the dinosaur infested island of ARK: Survival Evolved.
						</div>
						<div className="text" style={{color: '#a2ff00'}}>
							Pricing starts at $22.50 per month for 30 slots.
						</div>
						<a className="hugebutton" href="gameservers/ark-survival-evolved">SEE DETAILS <i className="fa fa-angle-double-right" /></a>
					</div>
				</div>
				<div className={`slider type-1 ${selectedTab.index != 1 ? "hidden" : ""}`}>
					<div className="bkg-image" style={{backgroundImage: `url(${vpsMinPic})`}} />
					<div className="body">
						<div className="title" style={{color: '#fff'}}>
							VPS SERVER HOSTING
						</div>
						<div className="subtitle" style={{color: '#fff'}}>
							KVM, Dedicated Resources, RAID10 SSD all with Remote Management
						</div>
						<div className="text" style={{color: '#a2ff00'}}>
							Pricing starts at $10.00 per month for starter package
						</div>
						<a className="hugebutton" href="/vps/australia/sydney">SEE DETAILS <i className="fa fa-angle-double-right" /></a>
					</div>
				</div>
				<div className={`slider type-1 ${selectedTab.index != 2 ? "hidden" : ""}`}>
					<div className="bkg-image" style={{backgroundImage: `url(${networkMinPic})`}} />
					<div className="body">
						<div className="title" style={{color: '#fff'}}>
							DDOS PROTECTED NETWORK
						</div>
						<div className="subtitle" style={{color: '#fff'}}>
							Gaming grade DDoS mitigation architecture designed to keep your server online
						</div>
						<div className="text" style={{color: '#a2ff00'}}>
							Backed by Corero Denial of Service Protection
						</div>
						<a className="hugebutton" href="ddos-protection">SEE DETAILS <i className="fa fa-angle-double-right" /></a>
					</div>
				</div>
				<div className={`slider type-1 ${selectedTab.index != 3 ? "hidden" : ""}`}>
					<div className="bkg-image" style={{backgroundImage: `url(${equinixMinPic})`}} />
					<div className="body">
						<div className="title" style={{color: '#fff'}}>
							AUSTRALIAN DEDICATED SERVERS
						</div>
						<div className="subtitle" style={{color: '#fff'}}>
							Boasting the latest Intel CPUs, combined with the speed and reliability of SSD’s
						</div>
						<div className="text" style={{color: '#a2ff00'}}>
							Pricing starts at $150.00 per month
						</div>
						<a className="hugebutton" href="/dedicated/australia">SEE DETAILS <i className="fa fa-angle-double-right" /></a>
					</div>
				</div>
			</div>
			<div className="slider-buttons">
				<div className="back">
					<div className="body">
						<div className="outer">
							<div className="inner">
								<div className="button-strip">
									<a onClick={() => setSelectedTab({index: 0, short: false})} className={`${selectedTab.index == 0 ? "active" : ""}`} style={{color: '#0290d6', cursor: "pointer"}}>
										<strong>Game Servers</strong>
										<span style={{color: '#ffffff'}}>FROM <b>$2.50</b> /MO</span>
									</a>
								</div>
								<div className="button-strip">
									<a onClick={() => setSelectedTab({index: 1, short: false})} className={`${selectedTab.index == 1 ? "active" : ""}`} style={{color: '#009c4e', cursor: "pointer"}}>
										<strong>VPS Servers</strong>
										<span style={{color: '#00ff7f'}}>FROM <b>$10.00</b> /MO</span>
									</a>
								</div>
							</div>
						</div>
						<div className="outer">
							<div className="inner">
								<div className="button-strip">
									<a onClick={() => setSelectedTab({index: 2, short: false})} className={`${selectedTab.index == 2 ? "active" : ""}`} style={{color: '#d23131', cursor: "pointer"}}>
										<strong>DDoS Protected Network</strong>
										<span style={{color: '#ffffff'}}>ACROSS <b>3</b> CONTINENTS</span>
									</a>
								</div>
								<div className="button-strip">
									<a onClick={() => setSelectedTab({index: 3, short: false})} className={`${selectedTab.index == 3 ? "active" : ""}`} style={{color: '#9bb900', cursor: "pointer"}}>
										<strong>AU Dedicated Servers</strong>
										<span style={{color: '#ffffff'}}>FROM <b>$150</b> /MO</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hex-area">
				<div className="hex-animation" />
				{/* */}
				
				<div className="body">
					<div className="intro-title">
						SERVER HOSTING
						<strong>LIKE A <em>HERO</em></strong>
					</div>
					<div className="intro-text">
						GoGaming is a premium game, voice, web and dedicated hosting provider with an enterprise worldwide network.
					</div>
				</div>
				<div className="body">
					<div className="home-services">
						<div className="service service-icon-game" style={{color: '#0291d6'}}>
							<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
							<div className="title">
								GAME SERVERS
							</div>
							<div className="price">
								<sup>From</sup>
								<div className="dollar"><em>2</em>.50</div>
								<small>PER MONTH</small>
							</div>
							<div className="content">
								<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
									No expense is spared when it comes to our game servers, boasting the latest titles on the fastest hardware.
								</div>
								<span className="button">
									<a href="gameservers">
										GO <i className="fa fa-angle-double-right" />
									</a>
								</span>
							</div>
						</div>
						<div className="service service-icon-voice" style={{color: '#009c4e'}}>
							<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
							<div className="title">
								TS3 VOICE SERVERS
							</div>
							<div className="price">
								<sup>From</sup>
								<div className="dollar"><em>2</em>.50</div>
								<small>PER MONTH</small>
							</div>
							<div className="content">
								<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
									Whether you need a Teamspeak 3 server for your team, community or business we’ve got you covered.
								</div>
								<span className="button">
									<a href="/voiceservers/teamspeak-3">
										GO <i className="fa fa-angle-double-right" />
									</a>
								</span>
							</div>
						</div>
						<div className="service service-icon-dedi" style={{color: '#677b00'}}>
							<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
							<div className="title">
								DEDICATED
							</div>
							<div className="price">
								<sup>From</sup>
								<div className="dollar"><em>150</em></div>
								<small>PER MONTH</small>
							</div>
							<div className="content">
								<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
									High quality, blazing fast dedicated servers for when your gaming community needs some serious power.
								</div>
								<span className="button">
									<a href="/australiadedicated">
										GO <i className="fa fa-angle-double-right" />
									</a>
								</span>
							</div>
						</div>
						<div className="service service-icon-hosting" style={{color: '#0291d6'}}>
							<div className="style"><div></div><div></div><div></div><div></div><div></div></div>
							<div className="title">
								VPS HOSTING
							</div>
							<div className="price">
								<sup>From</sup>
								<div className="dollar"><em>10</em>.00</div>
								<small>PER MONTH</small>
							</div>
							<div className="content">
								<div className="text box-text" style={{height: maxHeight ? (maxHeight + "px") : ""}}>
									GoGaming also offers business grade VPS servers, keeping you connected to the cloud.
								</div>
								<span className="button">
									<a href="/vps-australia">
										GO <i className="fa fa-angle-double-right" />
									</a>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>


			<BigMap hideAbout={true}/>


			<div className="home-about">
				<div className="body">
					<h1>
					ABOUT <span className="gogaming-colors"><em>GO</em>GAMING</span> SERVER HOSTING
					</h1>
					<div className="desc">
					GoGaming is the choice for hosting when you need a reliable, high quality and DDoS protected server. We have established ourselves as a leader in the gaming industry, and will continue to push the boundaries of the industry.
					</div>
				</div>
				<div className="bar">
					<div className="body">
					<div className="home-about-block">
						<h2><i className="fa fa-asl-interpreting" /> Customer Service</h2>
						<p>
						Whether you have a quick question about your server, or need an in-depth assistance on a managed dedicated server GoGaming has you covered. We pride ourselves on interactions with our customers, we are always here to help when you need it. Don’t hesitate to contact us today.
						</p>
					</div>
					<div className="home-about-block">
						<h2><i className="fa fa-cubes" /> Instant Activation and Setup</h2>
						<p>
						GoGaming knows time is of the essence, with us there is no more waiting for your hosting to be setup! We offer instant provisioning on our game, voice and web servers. You’ll be online within minutes of ordering your server.
						</p>
					</div>
					</div>
				</div>
				<Link to="about" className="bigbutton">LEARN MORE ABOUT US <i className="fa fa-angle-double-right" /></Link>
			</div>
		</div>
	);
}

export default Home;
