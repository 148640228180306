import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import VPSData from 'resources/vps.js';

import Servers from 'components/layouts/VPSServers/VPSServers.jsx';

import serverIconDedi from 'images/service-icon-dedi.png';

function VPS(props) {
	let isCountryView = props.match.params.country && !props.match.params.city;
	let data = VPSData.find((country) => country.url === props.match.params.country);
	let cityData = !isCountryView && data.cities.find((city) => city.url === props.match.params.city);


	if (!data || (!cityData && !isCountryView)) {
		return (
			<Redirect to="/404" />
		)
	}
	if (isCountryView) {
		let defaultCity = data.cities.find((city) => city.default);
		if (defaultCity) {
			return <Redirect to={`/vps/${data.url}/${defaultCity.url}`} />;
		} else {
			return <Redirect to="/404" />;
		}
	}
	let lowest = cityData || data;
	let title = `${lowest.localised} VPS Hosting`;
	return (
		<div className="VPS">
			<Helmet>
				<title>{title} - GoGaming</title>
				<meta name="description" content={lowest.description} />
			</Helmet>
			<div className="dedicated-header-back">
				<div className="dedicated-header">
					<div className="body">
						<div className="big-heading">
							
							<div className="container">
								<div className="supertitle">
									{lowest.localised.toUpperCase()}
								</div>
								<div className="title ">
									VPS HOSTING
								</div>
								<div className="subtitle">
									KVM Virtualization, Dedicated Resources, Instant Setup and RAID10 SSD all with Remote Management and advanced DDoS Protection.
								</div>
							</div>
							
						</div>
					</div>
					
					
				</div>
			</div>
			
			<Servers type="vps" data={data} isCountryView={isCountryView} cityData={cityData} city={props.match.params.city} history={props.history} location={props.location} />
			
			<div className="web-hosting-features-area">
				<div className="body">
					<div className="web-hosting-features">
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							<strong>Instant</strong> Setup
						</div>
						<div className="text">
							Once you have completed your order, our automated system will instantly create your VPS! This process normally takes 5-10 minutes depending on the chosen Operating System.
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							<em>Advanced</em> Remote Management
						</div>
						<div className="text">
							All our servers include advanced management tools to control your VPS. Tools such as Start/Stop/Restart options, HTML NoVNC Console, Operating System Reinstalls and easy upgradedable options for your VPS.
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							DDoS <strong>Protection</strong>
						</div>
						<div className="text">
							Our Australian VPS servers are protected by gaming grade Corero denial of service protection.<br /><a href="ddos-protection.php">Learn more about our DDoS protection</a>
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							<em>RAID10</em> SSDs
						</div>
						<div className="text">
							All our virtual servers are powered by enterprise grade SSDs in RAID10 arrays.  To ensure your VPS will have the best performance while keeping your data protected from corruption.
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>




			<div className="body">
				<div className="big-heading purple">
				<div className="supertitle">
					FAQ
				</div>
				<div className="subtitle">
					Frequently Asked Questions
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					What type of Virtualisation do you use?
					</div>
					<div className="text">
					All our VPS nodes run off KVM virtualization.
					</div>
				</div>
				<div className="box">
					<div className="title">
					What Operating Systems do you host?
					</div>
					<div className="text">
					Currently we are only hosting 64bit Linux servers. These operating systems include: CentOS 6/7, Debian 9, Fedora 26 and Ubuntu 14/16/17.
					</div>
				</div>
				<div className="box">
					<div className="title">
					The operating system I want to use isn't listed, can I upload my own?
					</div>
					<div className="text">
					You may <a href="https://billing.gogaming.com/contact.php">contact our support team</a> to request a manual installation of your chosen OS.
					</div>
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					What ports do you block?
					</div>
					<div className="text">
					We block ports (TCP/UDP) 17, 19, 1900 and 53413.
					Outbound SMTP (port 25) is also blocked by default. This block can be removed by <a href="https://billing.gogaming.com/contact.php">opening a support ticket</a>.
					</div>
				</div>
				<div className="box">
					<div className="title">
					What data centre does GoGaming use in Sydney, Australia?
					</div>
					<div className="text">
					Our VPS systems in Sydney Australia are housed inside the premier and latest Equinix SY4 data centre. This has connection to all of our peering and transit providers, ensuring optimal latency on the network.
					</div>
				</div>
				<div className="box">
					<div className="title">
					Does GoGaming provide backups?
					</div>
					<div className="text">
					We currently backup your VPS server automatically, this backup is performed daily and can be monitored via our billing panel.
					</div>
				</div>
				</div>
			</div>
			<div className="center padded">
				<a href="https://billing.gogaming.com/contact.php" className="bigbutton">
				CONTACT US TO ASK QUESTIONS
				<i className="fa fa-share" />
				</a>
			</div>


		</div>
	);
};

export default VPS;