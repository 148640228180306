import React from 'react';
import { Link } from 'react-router-dom';

function City({data}) {
	let localisedType = "VPS Hosting Packages";
	return (
		<div key={data.url}>
			<div className="server-type">
				{data.localised} {localisedType}
			</div>
			
			<div class="web-hosting-boxes">
				{data.servers.map((server) => (
				<div class="box">
					<div class="title">
						{server.type}
					</div>
					<div class="price">
						<div>
							<em>${server.price.dollars}<sup>.{server.price.cents}</sup></em>
							/mo
						</div>
					</div>
					<ul>
						<li class="intel">
							Powered by <b>Intel Xeon</b> Processors
						</li>
						<li>
							<em>{server.cpu.numCores} CPU </em>
							at {server.cpu.Ghz} Ghz
						</li>
						<li>
							<em>{server.ram} </em>
							RAM
						</li>
						<li>
							<em>{server.storage} </em>
							SSD Disk Space
						</li>
						<li>
							<em>{server.transfer} </em>
							Monthly Transfer
						</li>
						<li class="small">
							<em>{server.ips} IPv4 </em>
							Address
						</li>
						<li class="small">
							<em>{server.connectionSpeed} </em>
							Connection
						</li>
						<li class="small">
							<em>24/7 </em>
							Customer Support
						</li>
					</ul>
					
					<a href={server.url} class="button">ORDER NOW</a>
				</div>
				))}
		</div>
		</div>
	);
}

function Servers(props) {
	return (
		<>
		<div className="option-bar">
			<div className="body">
				{props.data.cities.map((city) => (
					<Link className={props.city === city.url ? "selected" : undefined} to={`/${props.type}/${props.data.url}/${city.url}`}>{city.localised}</Link>
				))}
			</div>
		</div>

		<div className="body">
			<City data={props.cityData}></City>
		</div>
		</>
	);
}

export default Servers;
