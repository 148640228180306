const PopularGames = [
	{
		'largeimage': 'images/games/main/main-arma.jpg',
		'hasflair': true,
		'flaircolor': '#9f2aff',
		'flairtext': "POPULAR GAME",
		'bigtitle': "Arma 3",
		'subtitle': "with EPOCH & EXILE",
		
		
		"img": "images/games/thumb/arma3-thumb.jpg",
		"min": "images/games/min/arma3-min.jpg",
		"title": "Arma 3 with EPOCH & EXILE",
		"price": "20.00",
		"slots": "20-100",
		"url": "arma3",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=17",

		"description": "Experience true combat gameplay in a massive military sandbox. Deploying a wide variety of single- and multiplayer content, over 20 vehicles and 40 weapons, and limitless opportunities for content creation, this is the PC’s premier military game. Authentic, diverse, open - Arma 3 sends you to war. GoGaming let you and your friends explore this massive military sandbox.",
		"faq": {
			"How are updates for Arma 3 with EPOCH & EXILE handled?": "If there is an update released for Arma 3 with EPOCH & EXILE all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Are we able to fully customize our server to our liking?": "Yes, our control panel provides an in-built file manager and also FTP access. This also includes the ability to change settings easily, such as the server name or starting mission.",
			"Do you offer mods within your game panel?": "Yes we do! We offer a large variety of mods such as (Altis life, Epoch, Exile, CUP) which saves you from having to upload 6+ GB mods."
		}
	},

	{
		'largeimage': 'images/games/main/main-ark.jpg',
		'hasflair': true,
		'flaircolor': '#ff450e',
		'flairtext': "POPULAR GAME",
		'bigtitle': "ARK: SE",
		'subtitle': "Survival Evolved",
		
		
		"img": "images/games/thumb/arkse-thumb.jpg",
		"min": "images/games/min/ark-min.jpg",
		"title": "ARK: Survival Evolved",
		"price": "12.00",
		"slots": "20-125",
		"url": "ark-survival-evolved",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=9",
		"description": "As a man or woman stranded naked, freezing and starving on the shores of a mysterious island called ARK, you must hunt, harvest resources, craft items, grow crops, research technologies, and build shelters to withstand the elements. Use your cunning and resources to kill or tame & breed the leviathan dinosaurs and other primeval creatures roaming the land, and team up with or prey on hundreds of other players to survive, dominate... and escape!",
		"faq": {
			"How are updates for ARK: Survival Evolved handled?": "If there is an update released for ARK: Survival Evolved all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What maps are Supported?": "We support all official maps by default, we also support the installation of modded maps from the Steam Workshop.",
			"Do you support clusters?": "Yes! Gogaming has an easy to use Cluster setup. Both servers can also be controlled separately to allow easier management."
		}
	},

	{
		
		'largeimage': 'images/games/main/main-csgo.jpg',
		'hasflair': true,
		'flaircolor': '#3ea70d',
		'flairtext': "HIGH QUALITY SERVERS",
		'bigtitle': "Counter Strike",
		'subtitle': "Global Offensive",
		
		
		"img": "images/games/thumb/csgo-thumb.jpg",
		"min": "images/games/min/csgo-min.jpg",
		"title": "Counter Strike: Global Offensive",
		"price": "8.00",
		"slots": "10-64",
		"url": "csgo",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=30",
		"description": "A multiplayer game developed with the sole purpose of becoming the ultimate first-person shooter. The game pits two teams against each other: the Terrorists and the Counter-Terrorists. Rent a game server from GoGaming, and get fragging today.",
		"faq": {
			"How are updates for Counter Strike: Global Offensive handled?": "If there is an update released for Counter Strike: Global Offensive all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer Fast Download?": "Yes, we offer a free FastDL service with all our CS:GO servers. Simply upload any content that needs to be downloaded by clients and run the FastDL Sync button on your Game Panel.",
			"Do you offer any mods?": "We currently have both Metamod and Sourcemod on our 1 click install Mod Manager. From here you can install many Sourcemod based plugins to modify your server however you like."
		}
	},
	{
		
		'largeimage': 'images/games/main/main-rend.jpg',
		'hasflair': true,
		'flaircolor': '#0cb3c4',
		'flairtext': "NEW GAME",
		'bigtitle': "Rend",
		'subtitle': "Premium Hosting",
		
		
		"img": "images/games/thumb/rend.png",
		"min": "images/games/min/rend-min.jpg",
		"title": "Rend",
		"price": "15.00",
		"slots": "20-80",
		"url": "rend",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=123",
		"description": "Official High Quality Rend Game Server Hosting from GoGaming. The world of Rend is a harsh domain where the strong ascend as gods. Players must choose among three rival factions, combine forces to build their faction’s stronghold, and battle foes and brutal creatures.",
		"faq": {
			"How are updates for Rend handled?": "If there is an update released for Rend all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
];

const AllGames = [
	{
		"img": "images/games/thumb/unturned.png",
		"min": "images/games/min/unturned-min.jpg",
		"title": "Unturned",
		"price": "8.00",
		"slots": "8-24",
		"url": "unturned",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=73",
		"description": "Unturned is your classic zombie survival game, but with a twist… You better watch out for other players while you’re on one of GoGaming’s servers! You never know who is a friend and who is a foe.",
		"faq": {
			"How are updates for Unturned handled?": "If there is an update released for Unturned all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What mods do you offer?": "We currently have rocket as a 1 click install on our Mod Manager. We also offer a free MySQL server for any mods that may require it.",
		}
	},
		{
		"img": "images/games/thumb/atlas-thumb.jpg",
		"min": "images/games/min/atlas-min.jpg",
		"title": "Atlas",
		"price": "11.30",
		"slots": "10-150",
		"url": "atlas",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=137",
		"description": "Ahoy pirates! Physically sail in real-time across the vast oceans with an Atlas server hosted by GoGaming. Explorers will voyage to over 700 unique landmasses across 45,000 square kilometers, with thousands of Discovery Zones, and ten distinct world regions each having their own unique resources, creatures, secrets, and environment hazards!",
		"faq": {
			"How are updates for Atlas handled?": "If there is an update released for Atlas all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What maps are Supported?": "We support all official maps by default, we also support the installation of modded maps from the Steam Workshop.",
			"Do you support clusters?": "Yes! Gogaming has an easy to use Cluster setup. Both servers can also be controlled separately to allow easier management.",
		}
	},
		{
		"img": "images/games/thumb/gmod.png",
		"min": "images/games/min/gmod-min.jpg",
		"title": "Garry's Mod",
		"price": "8.00",
		"slots": "10-90",
		"url": "gmod",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=41",
		"description": "Garry's Mod is a physics sandbox. There aren't any predefined aims or goals. We give you the tools and server and leave you to play. Let your imagination and creativity run wild!",
		"faq": {
			"How are updates for Garry's Mod handled?": "If there is an update released for Garry's Mod all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer any mods?": "We currently offer a range of mods for Garry’s mod, such as DarkRP, Murder and PropHunt. Our systems also allow you to upload and add any mods you want!",
		}
	},
	{
		"img": "images/games/thumb/dnl.png",
		"min": "images/games/min/dnl-min.jpg",
		"title": "Dark and Light",
		"price": "20.00",
		"slots": "20-120",
		"url": "dark-and-light",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=32",
		"description": "As a lone explorer in the wilderness of Dark and Light, you must learn to understand the terrain, natural resources, as well as how to domesticate the local creatures and build a home. GoGaming will get your started, but the adventuring and survival is entirely up to you.",
		"faq": {
			"How are updates for Dark and Light handled?": "If there is an update released for Dark and Light all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
		{
		"img": "images/games/thumb/pixark.png",
		"min": "images/games/min/pixark-min.jpg",
		"title": "PixARK",
		"price": "18.00",
		"slots": "20-80",
		"url": "pixark",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=112",
		"description": "Be prepared to enter this world of mystery, danger, ancient dinosaurs, mythical beasts, and cubes in the new game PixARK officially hosted by GoGaming!",
		"faq": {
			"How are updates for PixARK handled?": "If there is an update released for PixARK all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/battalion.png",
		"min": "images/games/min/battalion-min.jpg",
		"title": "Battalion 1944",
		"price": "15.00",
		"slots": "12-24",
		"url": "battalion-1944",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=111",
		"description": "Battalion 1944 recaptures the core of classic competitive shooters and refines the ‘classic’ FPS feel for the next generation. GoGaming’s high quality servers will give you the best experience while you defending, fragging and enjoying yourself!",
		"faq": {
			"How are updates for Battalion 1944 handled?": "If there is an update released for Battalion 1944 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/dst-thumb.jpg",
		"min": "images/games/min/dst-min.jpg",
		"title": "Don't Starve Together",
		"price": "12.00",
		"slots": "12-36",
		"url": "dontstarve",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=37",
		"description": "Don't Starve Together is the standalone multiplayer expansion of the uncompromising wilderness survival game, Enter a strange and unexplored world full of strange creatures, dangers, and surprises. Gather resources to craft items and structures that match your survival style. Play your way as you unravel the mysteries of this strange land. Do whatever it takes, but most importantly, Don't Starve.",
		"faq": {
			"How are updates for Don't Starve Together handled?": "If there is an update released for Don't Starve Together all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Does GoGaming offer Caves for Don’t Starve Together?": "Yes we do, this requires multiple servers to be purchased and can be setup in our game panel.",
		}
	},
	{
		"img": "images/games/thumb/doi.png",
		"min": "images/games/min/doi-min.jpg",
		"title": "Day of Infamy",
		"price": "8.00",
		"slots": "10-64",
		"url": "day-of-infamy",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=35",
		"description": "Experience intense close-quarters battles in iconic WWII settings, Day of Infamy is a realistic teamwork-oriented shooter. GoGaming will be always be there for support, as you step into the battlefield and take on the enemy.",
		"faq": {
			"How are updates for Day of Infamy handled?": "If there is an update released for Day of Infamy all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
		{
		"img": "images/games/thumb/dow.png",
		"min": "images/games/min/dow-min.jpg",
		"title": "Days of War",
		"price": "16.00",
		"slots": "16-32",
		"url": "days-of-war",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=36",
		"description": "Grab your rifle and make those bullets count. Head back to the Second World War where combat was brutal, unforgiving, and required extensive teamwork. In this competitive FPS, players need to fall back on their marksmanship, skill, map awareness and tactical cunning. Like in true WW2 warfare, combat in Days of War is brutal.",
		"faq": {
			"How are updates for Days of War handled?": "If there is an update released for Days of War all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/rust-thumb.jpg",
		"min": "images/games/min/rust-min.jpg",
		"title": "Rust",
		"price": "11.00",
		"slots": "50-400",
		"url": "rust",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=60",
		"description": "Rust’s world is harsh. The environment is not kind. Bears and wolves will chase and kill you, let alone the other players! GoGaming provides the server. How you survive is entirely up to your quick thinking and skill. Do you have what it takes to survive in Rust?",
		"faq": {
			"How are updates for Rust handled?": "If there is an update released for Rust all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer Oxide support?": "We have Oxide on our 1 click mod installer. Once installed, we have a 1 click update button for both the server and Oxide. This can also be scheduled to run daily.",
		}
	}, 
	{
		"img": "images/games/thumb/hurt-thumb.jpg",
		"min": "images/games/min/hurtworld-min.jpg",
		"title": "Hurtworld",
		"price": "15.00",
		"slots": "30-60",
		"url": "hurtworld",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=44",
		"description": "Hurtworld is a hardcore multiplayer survival FPS with a focus on deep survival progression that doesn't become trivial once you establish some basic needs. Hurtworld tempts you out of your comfort zone with rich resources, better loot and higher tech. It will also throw more dangerous creatures, harsher environments and new challenges at you that can't be taken on without the proper gear or refined skill.",
		"faq": {
			"How are updates for Hurtworld handled?": "If there is an update released for Hurtworld all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer OXIDE in your control panel?": "Yes! OXIDE is provided within our Mod Manager as a one-click install. This then lets you install all of your favourite OXIDE plugins with either the inbuilt File Manager or FTP.",
		}
	},
	{
		"img": "images/games/thumb/mc-thumb.jpg",
		"min": "images/games/min/minecraft-min.jpg",
		"title": "Minecraft",
		"price": "3.75",
		"slots": "Unlimited",
		"url": "minecraft",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=87",
		"description": "Minecraft is a blocky sandbox which allows you to explore a world full of different spooky and funny creatures. It also offers a modding system which helps expand the gameplay mechanics to ensure that you don’t get bored!",
		"faq": {
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Are we able to fully customize our servers by adding mods or installing modpacks?": "Yes, we offer a Manager which assists you with installing the most played and recent Modpack’s and Server Wrappers (Spigot, CraftBukkit, etc). Anything else you can simply upload to your server!",
		}
	},
//	{
//		"img": "images/games/thumb/arma2.png",
//		"title": "Arma 2",
//		"price": "24.00",
//		"slots": "16-100",
//		"url": "arma2",
//		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=#",
//	},
//	{
//		"img": "images/games/thumb/arma2epoch.png",
//		"title": "Arma 2: EPOCH",
//		"price": "24.00",
//		"slots": "16-100",
//		"url": "arma-2-epoch",
//		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=14",
//	},
//	{
//		"img": "images/games/thumb/arma2oa.png",
//		"title": "Arma 2: Operation Arrowhead",
//		"price": "24.00",
//		"slots": "16-100",
//		"url": "arma2-oa",
//		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=15",
//	},
//	{
//		"img": "images/games/thumb/arma2co.png",
//		"title": "Arma 2: Combined Operations",
//		"price": "24.00",
//		"slots": "16-100",
//		"url": "arma2-co",
//		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=13",
//	},
	{
		"img": "images/games/thumb/assetto.png",
		"min": "images/games/min/assetto-min.jpg",
		"title": "Assetto Corsa",
		"price": "10.80",
		"slots": "12-32",
		"url": "assetto-corsa",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=18",
		"description": "Assetto Corsa is a racing simulation that attempts to offer a realistic driving experience with a variety of road and race cars through detailed physics and tyre simulation on race tracks recreated through laser-scanning technology. Race with your friends on some of the most realistically replicated tracks in any racing game!",
		"faq": {
			"How are updates for Assetto Corsa handled?": "If there is an update released for Assetto Corsa all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/blackmesa.png",
		"min": "images/games/min/blackmesa-min.jpg",
		"title": "Black Mesa",
		"price": "12.00",
		"slots": "12-32",
		"url": "black-mesa",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=19",
		"description": "Black Mesa is the award winning fan-made re-imagining of Gordon Freeman's landmark journey through the Black Mesa Research Facility. On a server from GoGaming you can relive Half-Life, and experience the game that raised the bar for the entire game industry all over again!",
		"faq": {
			"How are updates for Black Mesa handled?": "If there is an update released for Black Mesa all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/blackwake.png",
		"min": "images/games/min/blackwake-min.jpg",
		"title": "Blackwake",
		"price": "25.00",
		"slots": "54",
		"url": "blackwake",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=20",
		"description": "GoGaming is commanding you to man the cannons and fire off coordinated broadsides with other players to sink the opposing ship. You will fight as a crew of up to 16 real players on a ship led by another players. What are you waiting for? Live out your pirate dreams today!",
		"faq": {
			"How are updates for Blackwake handled?": "If there is an update released for Blackwake all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/cod4.png",
		"min": "images/games/min/cod4-min.jpg",
		"title": "Call of Duty 4: MW",
		"price": "5.00",
		"slots": "10-64",
		"url": "cod4",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=21",
		"description": "Call of Duty 4: Modern Warfare is a classic first-person shooter video game and the first Call of Duty installment to break away from its World War II setting, and set in the modern world. GoGaming provides the hardware you need to enjoy this amazing game.",
		"faq": {
			"How are updates for Call of Duty 4: MW handled?": "If there is an update released for Call of Duty 4: MW all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
		{
		"img": "images/games/thumb/conan.png",
		"min": "images/games/min/conan-min.jpg",
		"title": "Conan Exiles",
		"price": "35.00",
		"slots": "20-70",
		"url": "conan-exiles",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=25",
		"description": "You are an exile, outcast and downtrodden, sentenced to walk in a barbaric wasteland where the weak are crushed and only the strong can live. Hungry, thirsty, and alone, your first battle is against the harsh environment. Dominate the world alone on your own GoGaming server, or with enemies and allies in multiplayer.",
		"faq": {
			"How are updates for Conan Exiles handled?": "If there is an update released for Conan Exiles all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/chivalry.png",
		"min": "images/games/min/chivalry-min.jpg",
		"title": "Chivalry: Medieval Warfare",
		"price": "8.00",
		"slots": "8-50",
		"url": "chivalry",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=23",
		"description": "Besiege castles and raid villages in Chivalry: Medieval Warfare, this game is a first-person slasher with a focus on multiplayer. Featuring competitive online combat that seeks to capture the experience of truly being on a medieval battlefield. Armed with your server from GoGaming don’t be afraid to enter battle today!",
		"faq": {
			"How are updates for Chivalry: Medieval Warfare handled?": "If there is an update released for Chivalry: Medieval Warfare all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/citadel.png",
		"min": "images/games/min/citadel-min.jpg",
		"title": "Citadel: Forged with Fire",
		"price": "20.00",
		"slots": "20-120",
		"url": "citadel",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=24",
		"description": "Citadel: Forged With Fire is a massive online sandbox RPG with elements of magic, spellcasting and inter-kingdom conflict. As a newly minted apprentice of the magic arts, you will set off to investigate the dangerous world of Ignus. Your goal: create a name for yourself and achieve notoriety and power among the land’s ruling Houses.",
		"faq": {
			"How are updates for Citadel: Forged with Fire handled?": "If there is an update released for Citadel: Forged with Fire all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/css.png",
		"min": "images/games/min/css-min.jpg",
		"title": "Counter-Strike: Source",
		"price": "8.00",
		"slots": "10-64",
		"url": "css",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=31",
		"description": "Counter-Strike: Source is a remake of Counter-Strike, and consequently retains its team-based objective-oriented first-person shooter style gameplay. The aim of the game is to accomplish the objective, and of course kill the entire other team!",
		"faq": {
			"How are updates for Counter-Strike: Source handled?": "If there is an update released for Counter-Strike: Source all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer any mods for Counter-Strike: Source?": "We currently have both Metamod and Sourcemod on our 1 click install Mod Manager. From here you can install many Sourcemod based plugins to modify your server however you like.",
		}
	},
	{
		"img": "images/games/thumb/cs1.6.png",
		"min": "images/games/min/cs16-min.jpg",
		"title": "Counter-Strike: 1.6",
		"price": "8.00",
		"slots": "10-64",
		"url": "cs1.6",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=27",
		"description": "Counter-Strike is a classic FPS game in which players join either the terrorist team, the counter-terrorist team. Each team attempts to complete their mission objective and/or eliminate the opposing team. Take a trip back in time and experience the original Counter Strike game on a server powered by GoGaming.",
		"faq": {
			"How are updates for Counter-Strike: 1.6 handled?": "If there is an update released for Counter-Strike: 1.6 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer any mods for Counter-Strike 1.6?": "We currently have both Metamod and AmxModX on our 1 click install Mod Manager. From here you can install many AmdxModX based plugins to modify your server however you like.",
		}
	},
	{
		"img": "images/games/thumb/contagion.png",
		"min": "images/games/min/contagion-min.jpg",
		"title": "Contagion",
		"price": "4.00",
		"slots": "4-8",
		"url": "contagion",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=26",
		"description": "Contagion is a multiplayer focused game with 3 different game modes; Hunted, Escape, and Extraction. In all game modes, when a player is killed they respawn as a zombie and are tasked with helping the A.I.-controlled zombies hunt down the remaining human players. How will you survive the apocalypse?",
		"faq": {
			"How are updates for Contagion handled?": "If there is an update released for Contagion all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/dod.png",
		"min": "images/games/min/dod-min.jpg",
		"title": "Day of Defeat: 1.3",
		"price": "8.00",
		"slots": "10-32",
		"url": "dod",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=33",
		"description": "Day of Defeat a team-based multiplayer first-person shooter set in the European theatre of World War II on the Western front. Charge into battle with the confidence of a GoGaming server behind you.",
		"faq": {
			"How are updates for Day of Defeat: 1.3 handled?": "If there is an update released for Day of Defeat: 1.3 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/dods.png",
		"min": "images/games/min/dodsource-min.jpg",
		"title": "Day of Defeat: Source",
		"price": "8.00",
		"slots": "10-32",
		"url": "dods",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=34",
		"description": "Day of Defeat Source offers intense online action gameplay set in Europe during WWII. Assume the role of infantry, sniper or machine-gunner classes, and more. You won’t be disappointed with this WWII shooter, bring out your inner army man.",
		"faq": {
			"How are updates for Day of Defeat: Source handled?": "If there is an update released for Day of Defeat: Source all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
	{
		"img": "images/games/thumb/fortressf.png",
		"min": "images/games/min/fortressf-min.jpg",
		"title": "Fortress Forever",
		"price": "12.00",
		"slots": "12-32",
		"url": "fortress",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=39",
		"description": "Fortress Forever involves two or more teams, each with 9 available classes, competing in a variety of map-determined objectives (capture the flag, territorial control, invade and defend, etc). Each class has its own unique abilities and strengths that offer extremely varied playstyles. The gameplay ranges from fast-paced, competitive, and deep to absurd and chaotic.",
		"faq": {
			"How are updates for Fortress Forever handled?": "If there is an update released for Fortress Forever all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What features do you offer for Fortress Forever?": "We offer complete access to your Fortress Forever server via our easy to use control panel. This includes the ability to change settings easily, such as the server name or password.",
		}
	},
	{
		"img": "images/games/thumb/insurgency.png",
		"min": "images/games/min/insurgency-min.jpg",
		"title": "Insurgency 2014 SA",
		"price": "8.00",
		"slots": "10-64",
		"url": "insurgency",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=45",
		"description": "Insurgency is a realistic, intense close quarters combat game where your survival depends on your ability to work as a team and your skill as an FPS shooter. Winning several awards this game has been praised for its lethal but highly competitive gameplay with a balance between one-life gameplay and prolonged action.",
		"faq": {
			"How are updates for Insurgency 2014 SA handled?": "If there is an update released for Insurgency 2014 SA all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer free Fast Download with my server?": "Yes! Our game panel has an integrated feature that automatically syncs your custom maps and content to our local Fast Download server.",
			"Am I allowed to use Metamod and/or Sourcemod?": "We have both the latest versions of Metamod and Sourcemod in our game panel Mod Manager. Easily install Sourcemod in a matter of seconds!",
		}
	},
	{
		"img": "images/games/thumb/jc2mp.png",
		"min": "images/games/min/jc2-min.jpg",
		"title": "Just Cause 2: Multiplayer",
		"price": "4.00",
		"slots": "20-1000",
		"url": "jc2",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=46",
		"description": "Just Cause 2 Multiplayer Mod (or JC2-MP for short) is an open source project created by the community to bring multiplayer to this fantastic game! All the chaos and glory of the main game in a Multiplayer mod that allows you and your friends to enjoy the carnage together!",
		"faq": {
			"How are updates for Just Cause 2: Multiplayer handled?": "If there is an update released for Just Cause 2: Multiplayer all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you allow custom scripts?": "Yes we do. Not only can you upload your own, our Mod Manager includes up to 9 of the most common game mode scripts used by players!",
		}
	},
	{
		"img": "images/games/thumb/kf.png",
		"min": "images/games/min/kf1-min.jpg",
		"title": "Killing Floor",
		"price": "5.00",
		"slots": "6-12",
		"url": "killingfloor",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=48",
		"description": "Enjoy carnage, blood and British accents? Killing Floor is a 4-6 player co-op game that imports elements from Survival and Horror into one gruesome package. Aim of the game? Survive waves of failed mutant experiments to gain money and unlock an arsenal of weapons!",
		"faq": {
			"How are updates for Killing Floor handled?": "If there is an update released for Killing Floor all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Can I change the command line parameters such as Game Mode and Starting Map?": "Our game panel allows you to change these settings and save multiple command lines for ease of use.",
			"Do you offer free Fast Download with my server?": "Yes! Our game panel has an integrated feature that automatically syncs your custom maps and content to our local Fast Download server.",
		}
	},
	{
		"img": "images/games/thumb/lfd2.png",
		"min": "images/games/min/l4d2-min.jpg",
		"title": "Left 4 Dead 2",
		"price": "5.00",
		"slots": "8-20",
		"url": "l4d2",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=51",
		"description": "From the developers at Valve, Left 4 Dead 2 is an iconic game that was the first to introduce 4 co-op survival horror gameplay. This Source Engine game is highly customizable allowing Steam Workshop integration and promises a satisfying and uniquely challenging experience every time the game is played, custom-fitted to your style of play.",
		"faq": {
			"How are updates for Left 4 Dead 2 handled?": "If there is an update released for Left 4 Dead 2 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer free Fast Download with my server?": "Yes! Our game panel has an integrated feature that automatically syncs your custom maps and content to our local Fast Download server.",
			"Am I allowed to use Metamod and/or Sourcemod?": "We have both the latest versions of Metamod and Sourcemod in our game panel Mod Manager. Easily install Sourcemod in a matter of seconds!",
			"What else is included in your Mod Manager?": "We have over 10 mods included in our Mod Manager, all of which are the most commonly used when hosting a L4D2 server. If there is a mod you can’t see, contact our support team and we will add it!",
		}
	},
//	{
//		"img": "images/games/thumb/medeng.png",
//		"title": "Medieval Engineers",
//		"price": "12.00",
//		"slots": "8-40",
//		"url": "medieval-engineers",
//		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=52",
//	},
	{
		"img": "images/games/thumb/mta.png",
		"min": "images/games/min/mta-min.jpg",
		"title": "Multi Theft Auto",
		"price": "1.50",
		"slots": "10-500",
		"url": "mta",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=53",
		"description": "MTA is a multiplayer modification for many of Rockstar Games video games (SA, III & VC) which aims to provide a more complex and feature rich experience by enhancing some of the most used systems on other games.",
		"faq": {
			"How are updates for Multi Theft Auto handled?": "If there is an update released for Multi Theft Auto all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Are we able to choose what gamemode the service runs?": "Yes, our Game Panel allows you to change settings, upload files and gamemodes, etc, from your recently ordered server.",
		}
	},
	{
		"img": "images/games/thumb/ns2.png",
		"min": "images/games/min/ns2-min.jpg",
		"title": "Natural Selection 2",
		"price": "8.00",
		"slots": "8-24",
		"url": "ns2",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=54",
		"description": "Natural Selection 2 is an immersive, multiplayer shooter that pits aliens against humans in a strategic and action-packed struggle for survival. It seamlessly combines a shooter gameplay with a strategy game. Are you ready for the challenge?",
		"faq": {
			"How are updates for Natural Selection 2 handled?": "If there is an update released for Natural Selection 2 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Are NS2 servers limited in any way upon ordering?": "No, servers have only a File Extension Restriction to ensure that hazardous files aren’t uploaded to your server directory. Other than that, you’re more than welcome to tinker around with your server settings and files as much as you want.",
			"Are we allowed to upload mods or use the steam workshop?": "Yes, you’re able to use this through our Game Panel in order to fully customize your online experience.",
		}
	},
	{
		"img": "images/games/thumb/nomore.png",
		"min": "images/games/min/nmrih-min.jpg",
		"title": "No More Room in Hell",
		"price": "9.95",
		"slots": "8-20",
		"url": "nomore",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=55",
		"description": "NMRiH is a coop zombie survival game which features dynamic maps with tasks players have to complete in order to survive the horde. This game focus on teamwork due to the “by distance” communication system which is quite necessary to keep an eye on to avoid splitting from your group and dying alone in the shadows.",
		"faq": {
			"How are updates for No More Room in Hell handled?": "If there is an update released for No More Room in Hell all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Are we able to fully customize our servers by adding our own content?": "Yes, we offer tools to allow our customers to fully customize the way their services work and to control gameplay mechanics that are available.",
		}
	},
	{
		"img": "images/games/thumb/rok.png",
		"min": "images/games/min/rok-min.jpg",
		"title": "Reign Of Kings",
		"price": "15.00",
		"slots": "10-50",
		"url": "reign-of-kings",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=59",
		"description": "Struggle to survive in a harsh medieval world. Hunt wildlife, mine resources, build structures, and craft weapons and armor in order to protect yourself. GoGaming provides the server, you may form a guild, bring war to all opposition, and try to seize the throne and become king!",
		"faq": {
			"How are updates for Reign Of Kings handled?": "If there is an update released for Reign Of Kings all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer mod support?": "We sure do! You can install Oxide with 1-click via our ‘Mod Manger’. Get ahead of the rest with a Modded server from GoGaming",
		}
	},
	{
		"img": "images/games/thumb/samp.png",
		"min": "images/games/min/samp-min.jpg",
		"title": "SA:MP",
		"price": "6.25",
		"slots": "25-200",
		"url": "samp",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=61",
		"description": "SA-MP is a free Massively Multiplayer Online game mod for the PC version of Rockstar Games Grand Theft Auto: San Andreas. Build your empire with a server from GoGaming today!",
		"faq": {
			"How are updates for SA:MP handled?": "If there is an update released for SA:MP all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What configuration access do you offer?": "We offer access to all configuration files so that you can edit your service to your liking.",
			"Can I add my own plugins / gamemodes?": "Yes you can, we offer access to your service via FTP. So any gamemode files can be uploaded",
		}
	},
	{
		"img": "images/games/thumb/starbound.png",
		"min": "images/games/min/starbound-min.jpg",
		"title": "Starbound",
		"price": "16.00",
		"slots": "8-256",
		"url": "starbound",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=64",
		"description": "In Starbound, you create your own story - there’s no wrong way to play! You may choose to save the universe from the forces that destroyed your home, uncovering greater galactic mysteries in the process, or you may wish to forego a heroic journey entirely in favour of colonizing uncharted planets.",
		"faq": {
			"How are updates for Starbound handled?": "If there is an update released for Starbound all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you offer mod support?": "We sure do! You can install any mods you like from the Modding Resources via FTP.",
		}
	},
		{
		"img": "images/games/thumb/stationeers.jpg",
		"min": "images/games/min/stationeers-min.jpg",
		"title": "Stationeers",
		"price": "30.00",
		"slots": "16-64",
		"url": "stationeers",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=85",
		"description": "Stationeers puts you in control of the construction and management of a space station either by yourself in single player, or online multiplayer with your friends. Inspired by the beloved Space Station 13, complex atmospheric, electrical, manufacturing and other systems require your thought and management at all times. Managing your Space Station may be complex, But with GoGaming managing your server will be easy.",
		"faq": {
			"How are updates for Stationeers handled?": "If there is an update released for Stationeers all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What features do you offer for Sationeers?": "We offer complete access to your Stationeers server via our easy to use control panel. This includes the ability to change settings easily, such as the server name or starting map.",
		}
	},
	{
		"img": "images/games/thumb/tf2.png",
		"min": "images/games/min/tf2-min.jpg",
		"title": "Team Fortress 2",
		"price": "7.50",
		"slots": "10-32",
		"url": "tf2",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=67",
		"description": "Team Fortress 2 (TF2) is a free to play team-based First-Person shooter game. Play the classic game mode or try the new co-operative Mann vs Machine mode, where you face off against wave of robots. Whichever game mode you choose, GoGaming has the server for you.",
		"faq": {
			"How are updates for Team Fortress 2 handled?": "If there is an update released for Team Fortress 2 all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you Offer any mods?": "We currently have both Metamod and Sourcemod for Team Fortress 2 on our 1 click install Mod Manager. From here you can install many Sourcemod based plugins to modify your server however you like.",
		}
	},
	{
		"img": "images/games/thumb/tfclass.png",
		"min": "images/games/min/tf-min.jpg",
		"title": "Team Fortress Classic",
		"price": "7.50",
		"slots": "10-32",
		"url": "tfclassic",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=68",
		"description": "Two teams, 9 classes placed against each other in variety of scenarios, capture the flag, protect the VIP or control the territory in this classic FPS. Whether you want to play as a soldier and shoot rockets, or sneak up on an enemy as the spy, GoGaming has the server for you.",
		"faq": {
			"How are updates for Team Fortress Classic handled?": "If there is an update released for Team Fortress Classic all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What features do you offer for Team Fortress?": "We offer complete access to your Team Fortress server via our easy to use control panel. This includes the ability to change settings easily, such as the server name or starting map.",
		}
	},
	{
		"img": "images/games/thumb/terraria.png",
		"min": "images/games/min/terraria-min.jpg",
		"title": "Terraria",
		"price": "10.00",
		"slots": "10-32",
		"url": "terraria",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=69",
		"description": "The very world is at your fingertips as you fight for survival, fortune, and glory. Delve deep into cavernous expanses, seek out ever-greater foes to test your mettle in combat, or construct your own city. Gather, fight or build, on GoGaming the choice is yours!",
		"faq": {
			"How are updates for Terraria handled?": "If there is an update released for Terraria all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"Do you Offer any mods for Terraria?": "We currently offer tModLoader for Terraria on our 1 click install Mod Manager. From here you can install many tModLoader based plugins to modify your server however you like.",
		}
	},
	{
		"img": "images/games/thumb/tower-unite.png",
		"min": "images/games/min/towerunite-min.jpg",
		"title": "Tower Unite",
		"price": "3.75",
		"slots": "30-128",
		"url": "tower-unite",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=70",
		"description": "Tower Unite is a living, evolving world, fueled by the community. Play online games with your friends or make new friends from around the world! Tower Unite is a game that contains multiple large multiplayer games within it that you can enjoy with lots of players, each with its own gameplay and genre.",
		"faq": {
			"How are updates for Tower Unite handled?": "If there is an update released for Tower Unite all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
			"What features do you offer for Tower Unite?": "We offer complete access to your Tower Unite server via our easy to use control panel. This includes the ability to change settings easily, such as the server name or password.",
		}
	},
	{
		"img": "images/games/thumb/7days.png",
		"min": "images/games/min/7d2d-min.jpg",
		"title": "7 Days to Die",
		"price": "8.80",
		"slots": "8-32",
		"url": "7-days-to-die",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=10",
		"description": "Explore Huge, unique and rich environments in this survival horror game. A server from GoGaming lets you explore the open-world game that has incorporated elements from the first person shooter, survival horror, tower defense, and role-playing genres. It presents combat, crafting, looting, mining, exploration, and character growth.",
		"faq": {
			"How are updates for 7 Days to Die handled?": "If there is an update released for 7 Days to Die all you need to do is click ‘STEAM Update’ on our game panel. Our systems will validate your files and install the update automatically.",
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
];

const AllAllGames = PopularGames.concat(AllGames).sort((a, b) => {
	if (a.title < b.title) {
		return -1;
	} else {
		return 1;
	}
});

export {
	PopularGames,
	AllGames,
	AllAllGames,
};
