import React from 'react';
import { Link } from 'react-router-dom';

function BigMap(props) {
	return (
		<div className="white-back">
			<div className="body rel">
				<div className="bigmap-back">
				<div className="bigmap">
					<div className="bigmap-pins">
					<div className="pin" style={{left: 258, top: 192}}><pulse /><span>LOS ANGELES</span></div>
					<div className="pin" style={{left: 370, top: 167}}><pulse /><span>CHICAGO</span></div>
					<div className="pin" style={{left: 420, top: 173}}><pulse /><span>NEW YORK</span></div>
					<div className="pin" style={{left: 1121, top: 326}}><pulse /><span>SINGAPORE</span></div>
					<div className="pin" style={{left: 1286, top: 477}}><pulse /><span>MELBOURNE</span></div>
					<div className="pin" style={{left: 1300, top: 453}}><pulse /><span>SYDNEY</span></div>
					</div>
				</div>
				</div>
				{!props.hideText ? (
				<div className="bigmap-description">
				<div className="block">
					<h2>
					<span className="gogaming-colors"><em>GO</em>GAMING</span> NETWORK LOCATIONS
					</h2>
					<p>
					Our enterprise worldwide network boasts optimised routing, to keep the latency between you and the server to a minimum.
					</p>
					{!props.hideAbout ?
						<Link to="/" className="bigbutton">ABOUT OUR NETWORK <i className="fa fa-angle-double-right" /></Link>
					: ""}
				</div>
				</div>
				) : ""}
			</div>
		</div>
	)
}

export default BigMap;
