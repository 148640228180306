import React from 'react';
import { Helmet } from 'react-helmet';

import iconShieldBlue from 'images/icon-shield-blue.png';
import iconWallBlue from 'images/icon-wall-blue.png';

function DDoSProtection() {
	return (
		<div className="DDoSProtection">
			<Helmet>
				<title>Gaming Grade DDoS Protection - GoGaming</title>
				<meta name="description" content="Learn more about GoGaming’s, Enterprise grade gaming denial of service protection. Our worldwide network is protected by Corero mitigation clusters to ensure reliability during an attack." />
			</Helmet>
			<div className="ddos-header-back">
				<div className="ddos-header">
				<div className="body">
					<div className="big-heading left">
					<div className="container">
						<div className="supertitle">
						AUTONOMOUS &amp; PROACTIVE
						</div>
						<div className="title ">
						DDOS PROTECTION
						</div>
						<div className="subtitle">
						Our DDoS mitigation architecture automatically, and surgically removes DDoS attack traffic, while still allowing good user traffic to flow uninterrupted.
						</div>
					</div>
					</div>
					<div className="corero">
					</div>
				</div>
				</div>
			</div>
			<div className="ddos-feature-bar">
				<div className="body">
				<div className="feature">
					<div className="icon" style={{backgroundImage: `url(${iconShieldBlue})`}} />
					<div className="title">
					SmartWall® Threat Defense System
					</div>
					<div className="text">
					The SmartWall TDS family of solutions is capable of mitigating a wide range of DDoS attacks all while maintaining full connectivity to avoid disrupting the delivery of legitimate traffic. It is designed to handle large network-based DDoS attacks or floods, reflective amplified spoof attacks, as well as application layer attacks that are typically too low to be detected by out of band solutions. The SmartWall TDS detects and mitigates attacks in seconds, vs minutes saving you money and resources.
					</div>
				</div>
				<div className="feature">
					<div className="icon" style={{backgroundImage: `url(${iconWallBlue})`}} />
					<div className="title">
					SmartProtect Program
					</div>
					<div className="text">
					The frequency, size and sophistication of Denial of Service attacks is ever increasing year over year. Due to this, we understand the need for full visibility of your core network and any attacks that may be occurring at a given time. The Corero SmartProtect program allows us to provide full visibility of attacks towards your dedicated server. You can simply login and see the type of attack, the size and what it was targeting. No more guessing about if your dedicated server is undergoing a denial of service attack.
					</div>
				</div>
				</div>
			</div>
			<div className="ddos-armor">
				<div className="body">
				<div className="title-area">
					<div className="title">
					GO ARMOUR
					</div>
					<div className="subtitle">
					Ensuring your service stays online during an attack.
					</div>
				</div>
				<div className="ddos-graph">
					<div className="ani">
					<div className="lines" />
					<div className="lines" />
					<div className="good-guys" />
					<div className="attack">
						<div className="guide" />
						<div className="guide" />
						<div className="good" />
						<div className="good" />
						<div className="good" />
						<div className="bad" />
						<div className="bad" />
						<div className="bad" />
						<div className="bad small" />
						<div className="bad small" />
						<div className="bad small" />
					</div>
					</div>
					<div className="icon1" />
					<div className="icon2" />
					<div className="text1"><span className="gogaming-colors"><em>GO</em>GAMING</span> DDOS SHIELD</div>
					<div className="text2">Clean Traffic</div>
					<div className="text3">YOUR <span className="gogaming-colors"><em>GO</em>GAMING</span> SERVER</div>
				</div>
				</div>
			</div>
			<div className="body">
				<div className="big-heading">
				<div className="supertitle">
					Frequently asked questions about our Mitigation systems
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					<div className="fa fa-check" />
					What is a DDoS attack and why do I need protection from it?		
					</div>
					<div className="text">
					DDoS or distributed denial of service attack is where multiple compromised systems send malicious packets to a single / IP. This is in the hope that the attack can overwhelm the target system, and cause it to lag and or go completely offline.
					<br /><br />Our GoGaming protection, backed by a Corero SmartWall device utilizes extensive understandings of gaming traffic to inspect and mitigate in real time. This means you don’t need to worry about attacks attempting to take your service offline
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-check" />
					How does Corero DDoS protection help me?
					</div>
					<div className="text">
					The Corero SmartWall TDS is deployed at the network edge, in front of traditional edge network security infrastructure, detecting and mitigating DDoS attack in real-time, before any downstream servers or applications are impacted. Furthermore, the device allows us to inspect every packet entering the network. This means the device can protect against layer 4 &amp; 7 attack traffic. Why does this matter?<br /><br />
					Well, this gives us the ability to look at the payload of an attack, and learn the attack vectors to significantly speed up time of detection to mitigation.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-check" />
					What level of protection does the device provide?
					</div>
					<div className="text">
					-	Up to 20Gbps and 30Mpps DDoS Protection
					<br />
					-	All Layer 3 / 4 / 7 Floods
					<br />
					-	Application specific attack vectors (COD, VSE, TS)
					<br />
					-	UDP, TCP, SYN Filtering
					<br />
					-	In-line protection (Always on)
					<br />
					-	Customizable rules for heavily targeted clients
					<br />
					-	International PoP filtering (Australia, Singapore, America &amp; UK)
					</div>
				</div>
				</div>
			</div>
			<div className="center padded">
				<a href="https://billing.gogaming.com/contact.php" className="bigbutton">
				CONTACT US TO GET A QUOTE
				<i className="fa fa-share" />
				</a>
			</div>
			{/*?php include("footer.php"); ?*/}
		</div>
	);
}

export default DDoSProtection;
