import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

import Logo from 'images/logo.png';

import DedicatedData from 'resources/dedicated.js';
import VPSData from 'resources/vps.js';
import WebhostingData from 'resources/webhosting.js';

function Nav() {
	const [gamePanelDropped, setGamePanelDropped] = useState(false);
	const [navVisible, setNavVisible] = useState(false);
	const [isMobileView, setMobileView] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth > 970) {
				setMobileView(false);
				setNavVisible(false);
			} else {
				setMobileView(true);
			}
		}
		setTimeout(handleResize, 20);

		let bounced = debounce(handleResize, 100);
		window.addEventListener('resize', bounced)
	
		return _ => {
			window.removeEventListener('resize', bounced)
		}
	}, []);


	function toggleNav(e, stopPrevent) {
		if (!stopPrevent) {
			//e.preventDefault();
		}
		setNavVisible(!navVisible);
	}

	function NavMenuItem(props) {
		return (
			<li onClick={(e) => toggleNav(e, props.stopPrevent)}>{props.children}</li>
		)
	}

	return (
		<>
		<div id="header">
			<div className="body clearfix">
				
				<Link to="/" className="logo"><img src={Logo} alt="Logo" /></Link>
				
				
				<div id="menubutton">
					<a onClick={() => setNavVisible(!navVisible)} style={{cursor: "pointer"}} title="Menu"></a>
				</div>
				
				<div id="menu-area" className={navVisible ? "visible" : isMobileView ? "hidden" : ""}>
					
					<div className="top-buttons">
						<a className="my-account-button" href="https://billing.gogaming.com/clientarea.php">
							<i className="fa fa-user-o"></i>
							<span>My Account</span>
						</a>
						
						<a id="panelbutton" onClick={(e) => {e.preventDefault(); setGamePanelDropped(!gamePanelDropped)}}>
							<i className="fa fa-gamepad"></i>
							<span>Game Panels</span>
						</a>
						
						<div id="panellist" style={{display: `${gamePanelDropped ? "block" : "none"}`}}>
							<ul>
								<li><a href="https://cp.gogaming.com">AU Game Panel</a></li>
								<li><a href="https://us.gogaming.com">US Game Panel</a></li>
								<li><a href="https://billing.gogaming.com/contact.php">Support Helpdesk</a></li>
							</ul>
						</div>
					</div>
					
					<div className="social-icons">
						<a href="https://www.facebook.com/GoGameServers" target="_blank" className="facebook"><span>Facebook</span></a>
						<a href="https://twitter.com/gogameservers" target="_blank" className="twitter"><span>Twitter</span></a>
					</div>
					
					<div id="menu">
						<ul>
							<NavMenuItem>
								<Link to="/">Home</Link>
							</NavMenuItem>
							<NavMenuItem>
								<Link to="/gameservers">Game Servers</Link>
							</NavMenuItem>
							<NavMenuItem>
								<Link to="/voiceservers/teamspeak-3">Voice</Link>
							</NavMenuItem>
							<li className="dropdown">
								<a style={{cursor: "pointer"}}>Web Hosting</a>
								<div>
									<ul>
										{WebhostingData.map((country) => (
											<NavMenuItem key={country.url}><Link to={`/webhosting/${country.url}`}>{country.abbrevation} Web Hosting</Link></NavMenuItem>
										))}
									</ul>
								</div>
							</li>
							<li className="dropdown">
								<a style={{cursor: "pointer"}}>Dedicated</a>
								<div>
									<ul>
										{DedicatedData.map((country) => (
											<NavMenuItem key={country.url}><Link to={`/dedicated/${country.url}`}>{country.localised}</Link></NavMenuItem>
										))}
									</ul>
								</div>
							</li>
							<li className="dropdown">
								<a style={{cursor: "pointer"}}>VPS</a>
								<div>
									<ul>
										{VPSData.map((country) => (
											<NavMenuItem key={country.url}><Link to={`/vps/${country.url}`}>{country.localised}</Link></NavMenuItem>
										))}
									</ul>
								</div>
							</li>
							<li className="dropdown">
								<a style={{cursor: "pointer"}}>Other</a>
								<div>
									<ul>
										<NavMenuItem><Link to="/about">About us</Link></NavMenuItem>
										<NavMenuItem><Link to="/affiliate-program">Affiliate Program</Link></NavMenuItem>
										<li><a href="/legal/tos.pdf">Terms of Service</a></li>
										<li><a href="/legal/privacy-policy.pdf">Privacy Policy</a></li>
										<NavMenuItem><Link to="/ddos-protection">DDoS Protection</Link></NavMenuItem>
									</ul>
								</div>
							</li>
							
						</ul>
					
					</div>
				</div>
			</div>
		</div>
		</>
	);
}

export default Nav;
