import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import BigMap from 'components/layouts/BigMap/BigMap.jsx';

import { AllAllGames } from 'resources/games.js';
import VoiceServers from 'resources/voice.js';

import iconInstant from 'images/icon-instant.png';
import iconModding from 'images/icon-modding.png';
import iconControlPanel from 'images/icon-controlpanel.png';

function GameServer(props) {
	console.log(props);
	let isGame = !!props.match.params.game;
	let url = props.match.params.game || props.match.params.voice;
	let typeLocalised = isGame ? "Game" : "Voice";
	let data = (isGame ? AllAllGames : VoiceServers).find((game) => game.url.toLowerCase() === url.toLowerCase());

	const [image, setImage] = useState(false);
	useEffect(() => {
		import(`../../../${data.min}`)
		.then((image) => {
			setImage(image);
		})
	}, [data && data.img]);

	if (!data) {
		return (
			<Redirect to="/404" />
		)
	}

	return (
		<>
		{/*<meta name="Description" content="High Quality 7 Days to Die Game Server Hosting
		Get started from only $16.00/" />
		?php
		include("../_includes/list-of-games.php");
		$game = findGameByName("7 Days to Die");
		// use exact 'title' of the game from the list-of-games.php file

		$title = "Rent ".$game['title']." Game Servers";
		include("../header.php");
		?*/}
		<div className="game-header" style={{backgroundImage: `url(${image.default})`}}>
			<div className="body">
			<div className="game-name-box">
				<div className="title">
				{data.title}
				</div>
				<div className="subtitle">
				{data.description}
				</div>
			</div>
			</div>
		</div>
		<div className="game-pricing-bar">
			<div className="body">
			<div className="bar-content">
				<div className="box">
				<div className="content">
					<span>FROM</span>
					<strong>${data.price}</strong>
					<span>PER MONTH</span>
				</div>
				</div>
				<div className="box">
				<div className="content">
					<span>AVAILABLE FOR </span>
					<strong>{data.slots}</strong>
					<span>PLAYER SLOTS </span>
				</div>
				</div>
				<div className="box">
				<div className="content">
					<a className="big-order-button" href={data.cart}>
						<em>
						ORDER YOUR SERVER <i className="fa fa-angle-double-right" />
						</em>
					</a>
				</div>
				</div>
			</div>
			</div>
		</div>
		<div className="body padded">
			<div className="three-boxes">
			<div className="box center">
				<div className="icon" style={{backgroundImage: `url(${iconInstant})`}} />
				<div className="title">
				Instant Installation
				</div>
				<div className="text">
				After your order is placed and payment is confirmed, your service will be installed in minutes ready to play out of the box!
				</div>
			</div>
			<div className="box center">
				<div className="icon" style={{backgroundImage: `url(${iconModding})`}} />
				<div className="title">
				Server Modding
				</div>
				<div className="text">
				We currently offer a one click install system. Simply install any addons from our Mod Manager.
				</div>
			</div>
			<div className="box center">
				<div className="icon" style={{backgroundImage: `url(${iconControlPanel})`}} />
				<div className="title">
				Control Panel
				</div>
				<div className="text">
				With its flexible design and powerful features, our control panel gives end users complete control over their servers.
				</div>
			</div>
			</div>
			<div className="big-heading">
			<div className="supertitle">
				{data.title}
			</div>
			<div className="title">
				{typeLocalised.toUpperCase()} SERVER HOSTING
			</div>
			<div className="subtitle">
				Our premium network and hardware provides you with complete power over your server
			</div>
			</div>
		</div>
		<div className="half-size-map">
			<div className="body">
			<div className="map-part">
				<div className="big-heading lowpadding">
				<div className="supertitle">
					{typeLocalised} Server Locations
				</div>
				</div>

				<BigMap hideText={true} />
				{/*?php include("_includes/bigmap.php"); ?*/}
			</div>
			<div className="content-part">
				<div className="big-heading lowpadding">
				<div className="supertitle">
					{data.title} FAQ's
				</div>
				</div>

				<div class="faq-list">
					{data.faq ? Object.keys(data.faq).map((q) => (
						<>
						<div class="question">
							{q}
						</div>
						<div class="answer">
							{data.faq[q]}
						</div>
						</>
					)) : ""}
				</div>
			</div>
			</div>
		</div>
		<div className="center padded">
			<a className="bigbutton" href={data.cart}>
			ORDER YOUR {typeLocalised.toUpperCase()} SERVER NOW
			<i className="fa fa-angle-double-right" />
			</a>
		</div>
		</>

	);
}

export default GameServer;
