import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import DedicatedData from 'resources/dedicated.js';

import Servers from 'components/layouts/DedicatedServers/DedicatedServers.jsx';

import iconShieldBlue from 'images/icon-shield-blue.png';
import iconWallBlue from 'images/icon-wall-blue.png';

function Dedicated(props) {
	let isCountryView = props.match.params.country && !props.match.params.city;
	let data = DedicatedData.find((country) => country.url === props.match.params.country);
	let cityData = !isCountryView && data.cities.find((city) => city.url === props.match.params.city);


	if (!data || (!cityData && !isCountryView)) {
		return (
			<Redirect to="/404" />
		)
	}
	let lowest = cityData || data;
	let title = `${lowest.localised} Dedicated Servers`;
	return (
		<div className="Dedicated">
			<Helmet>
				<title>{title} - GoGaming</title>
				<meta name="description" content={`DDoS Protected Dedicated Servers in ${cityData ? `${cityData.localised}, ` : ""}Australia`} />
			</Helmet>
			<div className="dedicated-header-back">
				<div className="dedicated-header">
					<div className="body">
						<div className="big-heading">
							
							<div className="container">
								<div className="supertitle">
									{lowest.localised.toUpperCase()}
								</div>
								<div className="title ">
									DEDICATED SERVERS
								</div>
								<div className="subtitle">
									Don’t compromise on performance, pickup a dedicated server in Australia{cityData ? `, ${cityData.localised}` : undefined}.
								</div>
							</div>
							
						</div>
					</div>
					
					
				</div>
			</div>
			
			<Servers type="dedicated" data={data} isCountryView={isCountryView} cityData={cityData} city={props.match.params.city} history={props.history} location={props.location} />


			<div className="body">
				<div className="big-heading">
				<div className="subtitle">
					INCLUDED WITH EVERY SERVER
				</div>
				<div className="supertitle">
					DEDICATED SERVER FEATURES
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					<div className="fa fa-area-chart" />
					24/7/365 Support
					</div>
					<div className="text">
					Need assistance? You can rely on us, with our 24/7/365 round the clock support ticket access.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-balance-scale" />
					Dedicated Control Panel
					</div>
					<div className="text">
					We offer a fully integrated control panel, which will allow you to take full control of your dedicated server. In the event of remote connection not being accessible, we offer full IPMI (iKVM) to ensure you always have access to your server.
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-calendar" />
					99.95% Uptime
					</div>
					<div className="text">
					GoGaming boasts a 99.95% uptime SLA, you can trust our premier network and hardware solutions.
					</div>
				</div>
				</div>
				<div className="three-boxes">
				<div className="box">
					<div className="title">
					<div className="fa fa-dashboard" />
					Denial of Service Protection
					</div>
					<div className="text">
					Our Australian dedicated servers are protected by enterprise denial of service protection. <a href="ddos-protection.php">Learn more about our DDoS protection</a>
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-eject" />
					Ultra-low Latency Core Networks			
					</div>
					<div className="text">
					GoGaming provides an ultra-low latency core network, we utilize a combination of Arista and Juniper switches to ensure optimal performance			
					</div>
				</div>
				<div className="box">
					<div className="title">
					<div className="fa fa-feed" />
					Optimised Routing Network
					</div>
					<div className="text">
					We have designed our network around the goal of optimized routing to the Australian region, this allows us to provide low latency and stable connection to your business or community.			</div>
				</div>
				</div>
			</div>
			<div className="ddos-feature-bar">
				<div className="body">
				<div className="feature">
					<div className="icon" style={{backgroundImage: `url(${iconShieldBlue})`}} />
					<div className="title">
					Corero SmartWall® Threat Defense System
					</div>
					<div className="text">
					The SmartWall TDS family of solutions is capable of mitigating a wide range of DDoS attacks all while maintaining full connectivity to avoid disrupting the delivery of legitimate traffic. It is designed to handle large network-based DDoS attacks or floods, reflective amplified spoof attacks, as well as application layer attacks that are typically too low to be detected by out of band solutions. The SmartWall TDS detects and mitigates attacks in seconds, vs minutes saving you money and resources.
					</div>
				</div>
				<div className="feature">
					<div className="icon" style={{backgroundImage: `url(${iconWallBlue})`}} />
					<div className="title">
					Arista Core Network 
					</div>
					<div className="text">
					GoGaming utilises an Arista Networks for its backbone infrastructure. Arista’s award-winning platforms, redefine scalability, agility and resilience. They are able to provide second to none low latency connection for our clients dedicated servers. Experience the GoGaming, backed by Arista Networks difference today.
					</div>
				</div>
				</div>
			</div>

		</div>
	);
};

export default Dedicated;