const Voice = [
	{	
		"img": "images/games/thumb/teamspeak3.jpg",
		"min": "images/games/min/ts3-min.jpg",
		"title": "Teamspeak 3",
		"price": "2.00",
		"slots": "10-300",
		"url": "teamspeak-3",
		"cart": "https://billing.gogaming.com/cart.php?a=add&pid=92",

		"description": "Teamspeak 3 offers an ideal voice platform for gaming, education, community or business communications. GoGaming prides itself on low latency, high quality voice servers to ensure an optimal experience.",
		"faq": {
			"Do you offer denial of service protection?": "Yes, all of our game servers are protected by enterprise denial of service protection. Learn more about our DDoS protection",
			"How long after payment will my server be ready?": "We start the install of servers instantly after payment is received. For most games this usually only takes around 5 – 10 minutes. So, you’ll be on and gaming in no time at all!",
		}
	},
];

export default Voice;
