import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import WebPackages from 'components/layouts/WebPackages/WebPackages.jsx';

import WebhostingData from 'resources/webhosting.js';

import serverIconDedi from 'images/service-icon-dedi.png';

function WebHosting(props) {
	let data = WebhostingData.find((country) => country.url === props.match.params.country);
	if (!data) {
		return (
			<Redirect to="/404" />
		)
	}
	let title = `${data.localised} Web Hosting`;
	return (
		<div className="WebHosting">
			<Helmet>
				<title>{title} - GoGaming</title>
				<meta name="description" content={data.description} />
			</Helmet>
			<div className="dedicated-header-back">
				<div className="dedicated-header">
					<div className="body">
					<div className="big-heading">
						<div className="container">
						<div className="supertitle">
							{data.localised.toUpperCase()}
						</div>
						<div className="title ">
							WEB HOSTING
						</div>
						<div className="subtitle">
							Get the fastest speed with our SSD web hosting! Grow your community or just doodle around with your own web hosting!
						</div>
						</div>
					</div>
					</div>
				</div>
			</div>


			<div class="body">
				<div class="server-type">
					{data.localised} Web Hosting Packages
				</div>
					
				<WebPackages packages={data.plans}></WebPackages>
			</div>


			<div className="domain-area">
				<div className="body">
					<div className="title">
					REGISTER A DOMAIN NAME
					</div>
					<div className="domain-form">
					<form action="https://billing.gogaming.com/cart.php?a=add&domain=register" method="post">
						<input type="text" name="query" placeholder="yourdomain.com" />
						<input type="submit" defaultValue="GO" />
					</form>
					</div>
				</div>
			</div>

			<div className="web-hosting-features-area">
				<div className="body">
					<div className="web-hosting-features">
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							Instant <strong>Setup</strong>
						</div>
						<div className="text">
							Once you have completed your order, our automated system will instantly create your Web server. This process normally takes 1-5 minutes, you’ll then be ready to push your business to the world wide web.
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							DDoS <em>Protection</em>
						</div>
						<div className="text">
							Our Australian Web hosting servers are protected by inline enterprise grade Corero denial of service protection.
							<br /><a href="ddos-protection.php">Learn more about our DDoS protection</a>
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							RAID10 SSDs
						</div>
						<div className="text">
							All our Web hosting servers are powered by enterprise grade SSDs in RAID10 arrays. To ensure your website will have premium performance while keeping your data protected.
							<br />
							All our Web hosting servers are backed up daily by the Veeam platform. No need to worry about losing your website.
						</div>
						</div>
					</div>
					<div className="feature">
						<div className="icon" style={{backgroundImage: `url(${serverIconDedi})`}} />
						<div className="content">
						<div className="title">
							Control <strong>Panel</strong>
						</div>
						<div className="text">
							GoGaming understands the need for simplicity and reliability. Which is why we offer one of the easiest and most advanced web panels on the market, Cpanel. This powerful solution offers all the features you need for your website.
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>


			
		</div>
	);
}

export default WebHosting;
