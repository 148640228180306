const Dedicated = [
	{
		"url": "australia",
		"localised": "Australian",
		"description": "Premium, Reliable, Secure and Fast Australian Dedicated Servers. Solutions for your business from only $100",
		"cities": [
			{
				"url": "sydney",
				"localised": "Sydney",
				"description": "Reliable Dedicated Servers in Sydney, Australia. Solutions from only $120",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1241 v3",
						"cores": "4x 3.5 Ghz",
						"ram": "16 GB",
						"storage": "250 GB SSD",
						"transfer": "3 TB",
						"price": "$120",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=124",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1230 v3",
						"cores": "4x 3.3 Ghz",
						"ram": "16-32 GB",
						"storage": "500 GB SSD",
						"transfer": "3 TB",
						"price": "$130",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=162",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1240 v5/6",
						"cores": "4x 3.5 Ghz",
						"ram": "16 GB",
						"storage": "250 GB SSD",
						"transfer": "3 TB",
						"price": "$200",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=119",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1270 v5/6",
						"cores": "4x 3.6 Ghz",
						"ram": "16 GB",
						"storage": "250 GB SSD",
						"transfer": "3 TB",
						"price": "$220",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=120",
					},
					{
						"processorVendor": "Intel",
						"processor": "Core i7-7700K",
						"cores": "4x 4.2 Ghz",
						"ram": "32 GB",
						"storage": "500 GB SSD",
						"transfer": "3 TB",
						"price": "$300",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=121",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E2236",
						"cores": "6x 3.4 Ghz",
						"ram": "32 GB",
						"storage": "2x 500 GB SSD",
						"transfer": "10 TB",
						"price": "$245",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=167",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E2286G",
						"cores": "6x 4.0 Ghz",
						"ram": "64 GB",
						"storage": "2x 500 GB SSD",
						"transfer": "10 TB",
						"price": "$320",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=168",
					}
				],
			},
			{
				"url": "melbourne",
				"localised": "Melbourne",
				"description": "Reliable Dedicated Servers in Melbourne, Australia. Solutions from only $200",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Core i7-7700K",
						"cores": "4x 4.2 Ghz",
						"ram": "32 GB",
						"storage": "512 GB SSD",
						"transfer": "3 TB",
						"price": "$300",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=80",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1240 v6",
						"cores": "4x 3.5 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$200",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=81",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1270 v6",
						"cores": "4x 3.6 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$220",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=74",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1230 v3",
						"cores": "4x 3.4 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$200",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=143",
					}
				],
			},
			{
				"url": "perth",
				"localised": "Perth",
				"description": "Reliable Dedicated Servers in Perth, Australia. Solutions from only $200",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Core i7-7700K",
						"cores": "4x 4.2 Ghz",
						"ram": "32 GB",
						"storage": "512 GB SSD",
						"transfer": "3 TB",
						"price": "$300",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=84",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1240 v6",
						"cores": "4x 3.5 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$200",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=82",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1270 v6",
						"cores": "4x 3.6 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$220",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=83",
					}
				],
			},
			{
				"url": "adelaide",
				"localised": "Adelaide",
				"description": "Reliable Dedicated Servers in Adelaide, Australia. Solutions from only $200",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Core E2134",
						"cores": "4x 4.5 Ghz",
						"ram": "32 GB",
						"storage": "512 GB SSD",
						"transfer": "3 TB",
						"price": "$230",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=158",
					}
				],
			}
		]
	},
	{
		"url": "asia",
		"localised": "Asian",
		"description": "Premium, Reliable, Secure and Fast Asian Dedicated Servers. Solutions for your business from only $100",
		"cities": [
			{
				"url": "singapore",
				"localised": "Singapore",
				"description": "Reliable Dedicated Servers in Singapore. Solutions from only $200",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Xeon E5-1620v1/2",
						"cores": "4x 3.6 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$105",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=122",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1240 v6",
						"cores": "4x 3.5 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$150",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=6",
					},
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1270 v5/6",
						"cores": "4x 3.8 Ghz",
						"ram": "16 GB",
						"storage": "256 GB SSD",
						"transfer": "3 TB",
						"price": "$170",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=7",
					},
					{
						"processorVendor": "Intel",
						"processor": "Core i7-7700K",
						"cores": "4x 4.2 Ghz",
						"ram": "32 GB",
						"storage": "512 GB SSD",
						"transfer": "3 TB",
						"price": "$200",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=8",
					}
				],
			}
		]
	},
	{
		"url": "newzealand",
		"localised": "New Zealand",
		"description": "Premium, Reliable, Secure and Fast New Zealand Dedicated Servers. Solutions for your business from only $100",
		"cities": [
			{
				"url": "auckland",
				"localised": "Auckland",
				"description": "Reliable Dedicated Servers in Auckland, New Zealand. Solutions from only $180",
				"servers": [
					{
						"processorVendor": "Intel",
						"processor": "Xeon E3-1230 v2",
						"cores": "4x 3.3 Ghz",
						"ram": "16 GB",
						"storage": "512 GB SSD",
						"transfer": "3 TB",
						"price": "$180",
						"url": "https://billing.godedicated.com/cart.php?a=add&pid=136",
					},
				],
			}
		]
	}
];

export default Dedicated;
